<script setup lang="ts">
import { ref, nextTick, onMounted, defineComponent } from 'vue';
import { useField } from 'vee-validate';
import InputErrorMessage from './InputErrorMessage.vue';

defineComponent({ InputErrorMessage });

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  editMode: {
    type: Boolean,
    default: true
  },
  isWarning: {
    type: Boolean,
    default: false
  },
  errorInfo: {
    type: String,
    default: ''
  }
});

const currentYear = new Date().getFullYear();
const day = ref('');
const month = ref('');
const year = ref('');

const focusoutValidate = (event: any) => {
  nextTick(() => {
    const activeElementId = event?.relatedTarget?.id;
    if (
      activeElementId === `${props.name}-day` ||
      activeElementId === `${props.name}-month` ||
      activeElementId === `${props.name}-year`
    )
      return;
    setValue(day.value, month.value, year.value);
  });
};

const { value, errorMessage, meta, validate } = useField(() => props.name);

const setInitialValue = () => {
  if (meta.initialValue) {
    const dateParts = (meta.initialValue as string).split('-');
    if (dateParts.length === 3) {
      year.value = dateParts[0];
      month.value = dateParts[1];
      day.value = dateParts[2];
    }
    setValue(day.value, month.value, year.value);
  }
};

// Updated setValue function to ensure cross-browser date validation
const setValue = (day: string, month: string, year: string) => {
  const condition =
    day === '' || month === '' || year === '' || parseInt(day) < 1 || parseInt(month) < 1 || parseInt(year) < 1;
  if (!condition) {
    // Use Date object for validation
    const dateStr = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    const date = new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);

    // Check if the created date is valid
    if (!isNaN(date.getTime())) {
      value.value = dateStr;
    } else {
      value.value = '';
    }
  } else {
    value.value = '';
  }
};

const validateInputYear = () => {
  year.value = year.value.toString().replace(/\D/g, '');
  year.value = year.value.slice(0, 4);
};

const validateInputMonth = () => {
  month.value = month.value.toString().replace(/\D/g, '');
  month.value = month.value.slice(0, 2);
};

const validateInputDay = () => {
  day.value = day.value.toString().replace(/\D/g, '');
  day.value = day.value.slice(0, 2);
};

const formatDate = (date: string) => {
  if (!date) return 'N/A';
  const [year, month, day] = date.split('-');

  if (!year || !month || !day) return 'N/A';

  return `${day}-${month}-${year}`;
};

onMounted(() => {
  setInitialValue();
});
</script>

<template>
  <div :name="name">
    <div class="flex">
      <label
        class="text-sm sm:text-base font-bold"
        :class="{
          'text-medosync-dark-orange': isWarning && editMode,
          'text-medosync-red': errorMessage
        }"
      >
        {{ label }}
        <i v-if="isWarning && editMode" class="fal fa-circle-exclamation mx-1 text-medosync-dark-orange"></i>
      </label>
    </div>
    <div v-if="editMode" class="mt-1 ml-1 flex gap-6">
      <div>
        <label
          :for="`${name}Day`"
          class="text-sm ml-1"
          :class="{
            'text-medosync-dark-orange': isWarning && editMode,
            'text-medosync-red': errorMessage
          }"
        >
          Day
        </label>
        <input
          :id="`${name}-day`"
          v-model="day"
          :name="`${name}Day`"
          class="w-16"
          :class="{
            'border-medosync-dark-orange': isWarning && editMode,
            'border-medosync-red focus:ring-medosync-red focus:border-medosync-red': errorMessage
          }"
          :min="1"
          :max="31"
          type="number"
          placeholder="DD"
          inputmode="numeric"
          @focusout="focusoutValidate"
          @input="validateInputDay"
        />
      </div>
      <div>
        <label
          :for="`${props.name}Month`"
          class="text-sm ml-1"
          :class="{
            'text-medosync-dark-orange': isWarning && editMode,
            'text-medosync-red': errorMessage
          }"
        >
          Month
        </label>
        <input
          :id="`${name}-month`"
          v-model="month"
          :name="`${props.name}Month`"
          class="w-16"
          :class="{
            'border-medosync-dark-orange': isWarning && editMode,
            'border-medosync-red focus:ring-medosync-red focus:border-medosync-red': errorMessage
          }"
          :min="1"
          :max="12"
          type="number"
          placeholder="MM"
          inputmode="numeric"
          @focusout="focusoutValidate"
          @input="validateInputMonth"
        />
      </div>
      <div>
        <label
          :for="`${props.name}Year`"
          class="text-sm ml-1"
          :class="{
            'text-medosync-dark-orange': isWarning && editMode,
            'text-medosync-red': errorMessage
          }"
        >
          Year
        </label>
        <input
          :id="`${name}-year`"
          v-model="year"
          :name="`${props.name}Year`"
          class="w-20"
          :class="{
            'border-medosync-dark-orange': isWarning && editMode,
            'border-medosync-red focus:ring-medosync-red focus:border-medosync-red': errorMessage
          }"
          :min="currentYear - 120"
          :max="currentYear"
          type="number"
          placeholder="YYYY"
          inputmode="numeric"
          @focusout="focusoutValidate"
          @input="validateInputYear"
        />
      </div>
    </div>
    <div v-else class="mt-1 ml-1 flex gap-6">
      <p :id="id + '-readonly'" class="text-lg text-gray-500 ml-4 mr-6">
        {{ value === '' || value === undefined || value === null ? 'N/A' : formatDate(value) }}
      </p>
    </div>
    <InputErrorMessage v-if="errorMessage && errorInfo === ''">
      Please enter valid {{ label.toLowerCase().slice(0, -1) }}
    </InputErrorMessage>
    <InputErrorMessage v-if="errorMessage && errorInfo">{{ errorInfo }}</InputErrorMessage>
  </div>
</template>

<style scoped>
input {
  @apply focus:ring-medosync-blue focus:border-medosync-blue block sm:text-sm rounded-md h-12 w-20 placeholder:font-bold placeholder:text-medosync-grey;
  height: 40px;
  width: 90px;
}
</style>
