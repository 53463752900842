<template>
  <div>
    <h5
      class="font-bold"
      :class="{
        'text-medosync-dark-orange': isWarning && editMode,
        'text-medosync-red': signatureTooSmall || errors
      }"
    >
      Sign in the form with your name*
    </h5>

    <div v-if="isMobile && !isMobileLandscape && landScapeMode" class="text-start">
      <span v-if="!receivedSignatureImage">Turn your mobile phone horizontally for a better signing experience</span>

      <img
        v-if="!receivedSignatureImage"
        src="../../../assets/images/turnhorizontal.gif"
        class="m-2 w-36 mx-auto bg-medosync-dark-red rounded-full"
      />

      <div v-if="!receivedSignatureImage">
        <!-- Canvas for drawing signatures with mouse and touch events -->
        <div class="relative">
          <canvas
            ref="canvas"
            class="border-2 border-black rounded"
            :class="{ 'border-medosync-dark-orange': isWarning && editMode }"
            :width="canvasWidth"
            :height="canvasHeight"
            @mousedown="startDrawing"
            @mousemove="draw"
            @mouseup="endDrawing"
            @mouseleave="endDrawing"
            @touchstart="startDrawingTouch"
            @touchmove="drawTouch"
            @touchend="endDrawing"
          />
          <p
            v-if="editMode && !under16"
            class="small-text mt-2 text-start p-2 absolute"
            :class="{
              'text-medosync-dark-orange': isWarning && editMode,
              'text-medosync-red': signatureTooSmall || errors
            }"
            style="bottom: 10px; left: 10px; z-index: 10000"
          >
            <span class="font-bold">Signature of Patient or Parent/Legal Guardian</span><br />
            (on behalf of dependant under 18 years at the time of admission)**
          </p>
          <p
            v-if="editMode && under16"
            class="small-text mt-2 text-start p-2 absolute"
            :class="{
              'text-medosync-dark-orange': isWarning && editMode,
              'text-medosync-red': signatureTooSmall || errors
            }"
            style="bottom: 10px; left: 10px; z-index: 10000"
          >
            <span class="font-bold">Signature of Patient or Parent/Legal Guardian</span><br />
            (on behalf of dependant under 16 years at the time of admission)**
          </p>
        </div>

        <p v-if="signatureTooSmall || errors" class="small-error-text text-medosync-red text-start mt-5">
          Please sign the form by using your computer mouse or finger on the screen of your mobile device.<br />
          One mistake can also be that your signature is too small.
        </p>

        <!-- Label for "X Signature" on other devices -->
        <div class="flex justify-end">
          <button v-if="editMode" class="bg-medosync-grey rounded-3xl mt-8 p-2 w-48 h-200" @click="clearCanvas">
            Try Again
          </button>
        </div>
        <!-- "Try Again" button on other devices -->
      </div>
      <div v-if="receivedSignatureImage && editMode">
        <img class="m-2 w-100 mx-auto" :src="receivedSignatureImage" alt="Signature" />
        <button class="bg-medosync-grey rounded-3xl mt-10 p-2 w-48 h-200" @click="clearImage">Try Again</button>
      </div>
    </div>

    <!-- Signature Pad for Mobile Landscape -->
    <div v-if="isMobile && isMobileLandscape && landScapeMode">
      <SignaturePadLandscape :signature="signature" @signatureSaved="handleSignatureSaved"></SignaturePadLandscape>
    </div>

    <!-- Signature Pad for Other Devices -->
    <div v-if="!isMobile && !landScapeMode">
      <div class="m-4 mt-0 ml-0" style="position: relative">
        <!-- Canvas for drawing signatures with mouse and touch events -->
        <canvas
          ref="canvas"
          class="border-2 border-black rounded"
          :class="{ 'border-medosync-dark-orange': isWarning && editMode }"
          :width="canvasWidth"
          :height="canvasHeight"
          @mousedown="startDrawing"
          @mousemove="draw"
          @mouseup="endDrawing"
          @mouseleave="endDrawing"
          @touchstart="startDrawingTouch"
          @touchmove="drawTouch"
          @touchend="endDrawing"
        />
        <!-- Label for "X Signature" on other devices -->
        <p
          v-if="editMode && !under16"
          id="signature-x-icon"
          class="text-sm mt-2 p-2"
          style="position: absolute; bottom: 10px; left: 10px; z-index: 10000"
          :class="{
            'text-medosync-dark-orange': isWarning && editMode,
            'text-medosync-red': signatureTooSmall || errors
          }"
        >
          <span class="font-bold">Signature of Patient or Parent/Legal Guardian</span><br />
          (on behalf of dependant under 18 years at the time of admission)**
        </p>
        <p
          v-if="editMode && under16"
          id="signature-x-icon"
          class="text-sm mt-2 p-2"
          style="position: absolute; bottom: 10px; left: 10px; z-index: 10000"
          :class="{
            'text-medosync-dark-orange': isWarning && editMode,
            'text-medosync-red': signatureTooSmall || errors
          }"
        >
          <span class="font-bold">Signature of Patient or Parent/Legal Guardian</span><br />
          (on behalf of dependant under 16 years at the time of admission)**
        </p>
        <!-- "Try Again" button on other devices -->
        <button
          v-if="editMode"
          id="try-again-button"
          class="bg-medosync-grey rounded-3xl mt-2 p-2 w-48 h-200"
          style="position: absolute; bottom: 0px; left: 730px"
          @click="clearCanvas"
        >
          Try Again
        </button>
      </div>
      <p v-if="signatureTooSmall || errors" class="text-sm text-medosync-red">
        Please sign the form by using your computer mouse or finger on the screen of your mobile device.<br />
        One mistake can also be that your signature is too small.
      </p>
      <!-- Display message if signature size is too small -->
    </div>
    <div v-if="!isMobile && landScapeMode">
      <div class="m-4 mt-0 ml-0" style="position: relative">
        <!-- Canvas for drawing signatures with mouse and touch events -->
        <canvas
          ref="canvas"
          class="border-2 border-black rounded"
          :class="{ 'border-medosync-dark-orange': isWarning && editMode }"
          :width="canvasWidth"
          :height="canvasHeight"
          @mousedown="startDrawing"
          @mousemove="draw"
          @mouseup="endDrawing"
          @mouseleave="endDrawing"
          @touchstart="startDrawingTouch"
          @touchmove="drawTouch"
          @touchend="endDrawing"
        />
        <!-- Label for "X Signature" on other devices -->
        <p
          v-if="editMode && !under16"
          id="signature-x-icon"
          class="text-sm mt-2 p-2"
          style="position: absolute; bottom: 10px; left: 10px; z-index: 10000"
          :class="{
            'text-medosync-dark-orange': isWarning && editMode,
            'text-medosync-red': signatureTooSmall || errors
          }"
        >
          <span class="font-bold">Signature of Patient or Parent/Legal Guardian</span>
          <br />
          (on behalf of dependant under 18 years at the time of admission)**
        </p>
        <p
          v-if="editMode && under16"
          id="signature-x-icon"
          class="text-sm mt-2 p-2"
          style="position: absolute; bottom: 10px; left: 10px; z-index: 10000"
          :class="{
            'text-medosync-dark-orange': isWarning && editMode,
            'text-medosync-red': signatureTooSmall || errors
          }"
        >
          <span class="font-bold">Signature of Patient or Parent/Legal Guardian</span>
          <br />
          (on behalf of dependant under 16 years at the time of admission)**
        </p>
        <!-- "Try Again" button on other devices -->
        <button
          v-if="editMode"
          id="try-again-button"
          class="bg-medosync-grey rounded-3xl mt-2 p-2 w-48 h-200"
          style="position: absolute; bottom: 0px; left: 730px"
          @click="clearCanvas"
        >
          Try Again
        </button>
      </div>
      <p v-if="signatureTooSmall || errors" class="text-sm text-medosync-red">
        Please sign the form by using your computer mouse or finger on the screen of your mobile device.<br />
        One mistake can also be that your signature is too small.
      </p>
      <!-- Display message if signature size is too small -->
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted, onUnmounted, onBeforeMount, watch } from 'vue';
import SignaturePadLandscape from './SignaturePadLandscape.vue';

const canvas = ref(null);
const ctx = ref(null);
const drawing = ref(false);
const lastX = ref(0);
const lastY = ref(0);
const minSignatureSize = 800;
const isMobileLandscape = ref(false);
const canvasWidth = ref(0);
const canvasHeight = ref(0);
const signatureTooSmall = ref(false);
const receivedSignatureImage = ref(null);
const isMobile = ref(false);

const props = defineProps({
  editMode: {
    type: Boolean,
    default: true
  },
  isWarning: {
    type: Boolean,
    default: false
  },
  signature: {
    type: String,
    required: false
  },
  errors: {
    type: String,
    default: null
  },
  landScapeMode: {
    type: Boolean,
    default: true
  },
  under16: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['signatureCaptured', 'onClear']);

function checkMobileLandscape() {
  isMobileLandscape.value = window.matchMedia('(max-width: 1024px) and (orientation: landscape)').matches;
  updateCanvasDimensions();
}

function clearImage() {
  updateCanvasDimensions();

  if (ctx.value) {
    ctx.value.clearRect(0, 0, canvasWidth.value, canvasHeight.value);
  }
  if (canvas.value) {
    ctx.value = canvas.value.getContext('2d');
  }
  clearCanvas();
  receivedSignatureImage.value = false;
}

function updateCanvasDimensions() {
  if (isMobile.value && !isMobileLandscape.value) {
    canvasWidth.value = window.innerWidth - 40;
    canvasHeight.value = 250;
  }
  if (isMobile.value && isMobileLandscape.value) {
    canvasWidth.value = window.innerWidth;
    canvasHeight.value = window.innerHeight;
  }
  if (!isMobile.value) {
    canvasWidth.value = 713;
    canvasHeight.value = 250;
  }
}

function startDrawing(e) {
  if (!props.editMode) return;
  drawing.value = true;
  lastX.value = e.clientX - canvas.value.getBoundingClientRect().left;
  lastY.value = e.clientY - canvas.value.getBoundingClientRect().top;
}

function startDrawingTouch(e) {
  if (!props.editMode) return;
  drawing.value = true;
  const touch = e.touches[0];
  lastX.value = touch.clientX - canvas.value.getBoundingClientRect().left;
  lastY.value = touch.clientY - canvas.value.getBoundingClientRect().top;
}

function handleSignatureSaved(signatureImage) {
  receivedSignatureImage.value = signatureImage;
  emit('signatureCaptured', signatureImage);
}

function draw(e) {
  if (!drawing.value || !props.editMode) return;
  const x = e.clientX - canvas.value.getBoundingClientRect().left;
  const y = e.clientY - canvas.value.getBoundingClientRect().top;
  if (ctx.value) {
    ctx.value.strokeStyle = 'black';
    ctx.value.lineWidth = 2;
    ctx.value.beginPath();
    ctx.value.moveTo(lastX.value, lastY.value);
    ctx.value.lineTo(x, y);
    ctx.value.stroke();
    lastX.value = x;
    lastY.value = y;
  }
}

function drawTouch(e) {
  if (!drawing.value || !props.editMode) return;
  const touch = e.touches[0];
  const x = touch.clientX - canvas.value.getBoundingClientRect().left;
  const y = touch.clientY - canvas.value.getBoundingClientRect().top;
  if (ctx.value) {
    ctx.value.strokeStyle = 'black';
    ctx.value.lineWidth = 2;
    ctx.value.beginPath();
    ctx.value.moveTo(lastX.value, lastY.value);
    ctx.value.lineTo(x, y);
    ctx.value.stroke();
    lastX.value = x;
    lastY.value = y;
  }
}

function endDrawing() {
  if (!props.editMode) return;
  drawing.value = false;
  checkSignatureSize();
}

function clearCanvas() {
  if (ctx.value) {
    ctx.value.clearRect(0, 0, canvasWidth.value, canvasHeight.value);
  }
  if (canvas.value) {
    ctx.value = canvas.value.getContext('2d');
  }
  emit('onClear');
}

function checkSignatureSize() {
  if (ctx.value) {
    const imageData = ctx.value.getImageData(0, 0, canvasWidth.value, canvasHeight.value).data;
    let pixelCount = 0;
    for (let i = 0; i < imageData.length; i += 4) {
      if (imageData[i + 3] !== 0) {
        pixelCount++;
      }
    }
    signatureTooSmall.value = pixelCount < minSignatureSize;
    if (!signatureTooSmall.value) {
      const dataUrl = canvas.value.toDataURL();
      emit('signatureCaptured', dataUrl);
    }
  }
}

function setInitialSignature() {
  if (props.signature) {
    const img = new Image();
    img.onload = () => {
      if (ctx.value) {
        ctx.value.drawImage(img, 0, 0);
      }
    };
    img.src = `data:image/png;base64,${props.signature}`;
  }
}

onMounted(() => {
  if (canvas.value) {
    ctx.value = canvas.value.getContext('2d');
  }
  canvasWidth.value = window.innerWidth;
  canvasHeight.value = window.innerHeight;
  checkMobileLandscape();
  window.addEventListener('resize', checkMobileLandscape);
  updateCanvasDimensions();
  setInitialSignature();
});

onBeforeMount(() => {
  isMobile.value = window.innerWidth <= 1024;
});

onUnmounted(() => {
  window.removeEventListener('resize', checkMobileLandscape);
});

watch(() => props.signature, setInitialSignature);
</script>

<style scoped>
/* Add any custom styles here */

button {
  z-index: 1;
}

canvas {
  background-color: #d9d9d9;
  position: relative;
}

.small-text {
  font-size: 10px;
  line-height: 15px;
}

.small-error-text {
  font-size: 11px;
  line-height: 15px;
}
</style>
