<template>
  <div>
    <SpinnerLoader v-if="isLoading" />
    <ToastComponent ref="toast"></ToastComponent>
  </div>

  <div v-if="invoices.length === 0" class="no-claims-text">
    <div v-if="claim" class="addAttachments">
      <div v-if="claim.claim_state !== 'paid_in_full'" class="m-8">
        No payment has been received from the insurer.
        <div>
          <router-link :to="{ name: 'finance' }" style="margin-top: 18px; text-decoration: underline"
            >Upload and scan an insurer Payment File in "Payments and Reconciliation"</router-link
          >
        </div>
      </div>
      <div v-else class="m-8">
        This claim was paid in full by the insurer.
        <div>
          <router-link :to="{ name: 'finance' }" style="margin-top: 18px; text-decoration: underline"
            >Find more details in “Payment and Reconciliation"</router-link
          >
        </div>
      </div>
    </div>
  </div>
  <div v-if="invoices.length > 0">
    <table>
      <thead>
        <tr>
          <th class="header-cell-table" :style="{ width: '44px' }">Done</th>
          <th class="header-cell-table" :style="{ width: '46px' }">Status</th>
          <th class="header-cell-table" :style="{ width: '84px' }">Date pended / Rejected</th>
          <th class="header-cell-table" :style="{ width: '84px' }">Invoiced</th>
          <th class="header-cell-table" :style="{ width: '84px' }">Payable</th>
          <th class="header-cell-table" :style="{ width: '84px' }">W/Tax</th>
          <th class="header-cell-table" :style="{ width: '84px' }">Adjustment</th>
          <th class="header-cell-table" :style="{ width: '190px' }">Description</th>
          <th class="header-cell-table" :style="{ width: '370px' }">Pended/rejected reason/comments</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(payment, index) in invoices" :key="index">
          <label class="table-cell items-center space-x-2 cursor-pointer">
            <input type="checkbox" class="hidden" :checked="isCheck(payment)" @change="toggleCheck(payment)" />
            <div
              class="w-5 h-5 m-1 rounded-full border-2 border-grey-600 flex items-center justify-center transition duration-300"
              :class="payment.processed_on ? 'bg-white border-green-600' : 'bg-white border-gray-400'"
            >
              <i v-if="payment.processed_on" class="text-green-600 fas fa-check"></i>
            </div>
          </label>
          <td class="table-cell">
            <i :class="getFileIcon(claim.claim_state)"></i>
          </td>
          <td v-if="payment.rejected" class="table-cell">{{ humanDate(payment.rejected.date) }}</td>
          <td v-else-if="payment.pended" class="table-cell">{{ humanDate(payment.pended.date) }}</td>
          <td v-else class="table-cell-left"></td>
          <td class="table-cell">{{ Number(payment.pricing.amount_invoiced).toFixed(2) }}</td>
          <td class="table-cell">{{ Number(payment.pricing.amount_payable).toFixed(2) }}</td>
          <td class="table-cell">{{ Number(payment.pricing.withholding_tax).toFixed(2) }}</td>
          <td class="table-cell">{{ Number(payment.pricing.adjustment_amount).toFixed(2) }}</td>
          <td class="table-cell-left">{{ payment.procedure_code }} - {{ payment.description }}</td>
          <td v-if="payment.rejected" class="table-cell-left">
            <span>{{ payment.comments }} - </span>
            <span>{{ payment.rejected.reason }}</span>
          </td>
          <td v-else-if="payment.pended" class="table-cell-left">
            <span>{{ payment.comments }} - </span>
            <span>{{ payment.pended.reason }}</span>
          </td>
          <td v-else class="table-cell-left">
            <span>{{ payment.comments }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import _ from 'lodash';
import { format } from 'date-fns';
import StringUtility from 'utils/stringUtility';

export default {
  name: 'InsurerPaymentFiles',
  props: {
    filteredData: null,
    appointmentId: { required: true },
    consultantId: { required: true },
    claimId: { required: true },
    claim: { default: '' }
  },

  components: { ToastComponent },

  data() {
    return {
      isLoading: false,
      invoices: [],
      showDeleteModal: false,
      array1: [],
      filteredData1: null,
      files: [],
      fileTodelete: '',
      showManageModalUpload: false,
      claim_state_str: ''
    };
  },

  mounted() {
    if (!this.filteredData || this.filteredData.length === 0) {
      return;
    }
    this.invoices = this.filteredData;
    this.getInvoice();
  },

  methods: {
    async updateStatusPayment(item) {
      const oapi = new OpenAPIJSON();
      try {
        await oapi.claim_statement_update(item);
      } catch (error) {
        this.$refs.toast.showToast(
          'Something went wrong updating the file. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    isCheck(item) {
      return item.processed_on !== null;
    },
    humanDate(date) {
      return StringUtility.formatDate(date);
    },
    toggleCheck(item) {
      if (item.processed_on != null) {
        item.processed_on = null;

        this.updateStatusPayment(item);

        return;
      }
      const dateObj = new Date();
      const formattedDate = format(dateObj, "yyyy-MM-dd'T'HH:mm:ss");

      item.processed_on = formattedDate;

      this.updateStatusPayment(item);
    },

    getFileIcon(file) {
      if (file === null || file === undefined) {
        return;
      }
      file = file.toUpperCase();

      if (file === 'PAID_IN_FULL') {
        return 'fa-solid text-xl text-green-500 fa-triangle-exclamation';
      }
      if (file === 'UNPAID') {
        return 'fa-solid text-xl text-green-500 fa-triangle-exclamation';
      }
      if (file === 'PART_PAID') {
        return ' fa-solid text-xl text-yellow-500 fa-triangle-exclamation ';
      }
      if (file === 'REJECTED') {
        return 'fa-solid text-xl text-red-500 fa-triangle-exclamation';
      }
      if (file === 'PENDED') {
        return 'fa-solid text-xl text-yellow-500 fa-triangle-exclamation';
      }
    },
    // updateStatusPayment() {
    //   this.array1 = this.filteredData[0].payments;

    //   this.array1.forEach(item => {
    //     const { amount_invoiced, amount_payable, rejected_date, pended_date } = item;
    //     const invoiced = parseFloat(amount_invoiced);
    //     const payable = parseFloat(amount_payable);

    //     if (invoiced === payable) {
    //       item.statusPayment = 'PAID';
    //     } else if (pended_date) {
    //       item.statusPayment = 'PENDED';
    //     } else if (amount_payable === '0.00' || rejected_date) {
    //       item.statusPayment = 'REJECTED';
    //     } else if (invoiced > payable) {
    //       item.statusPayment = 'PART_PAID';
    //     } else if (invoiced < payable) {
    //       item.statusPayment = 'PAID';
    //     }
    //   });
    // },
    formatToTwoDecimalPlaces(value) {
      return parseFloat(value).toFixed(2);
    },
    addProcedureCodeAndDescriptionIfValuesMatch() {
      this.array1 = this.filteredData;
      this.array2 = this.invoice.items;

      for (let i = 0; i < this.array1.length; i++) {
        this.array1[i].pricing.amount_invoiced = this.formatToTwoDecimalPlaces(this.array1[i].pricing.amount_invoiced);
        for (let j = 0; j < this.array2.length; j++) {
          this.array2[j].amount = this.formatToTwoDecimalPlaces(this.array2[j].amount);
          if (this.array1[i].pricing.amount_invoiced === this.array2[j].amount) {
            this.array1[i].procedure_code = this.array2[j].procedure_code;
            this.array1[i].description = this.array2[j].description;
            break;
          } else {
            this.array1[i].procedure_code = this.array2[j].procedure_code;
            this.array1[i].description = this.array2[j].description;
          }
        }
      }
    },

    async getInvoice() {
      const oapi = new OpenAPIJSON();
      let req = { claim_ref: { type: 'id', id: this.claim.claim_consultant_id } };

      await oapi
        .consultant_invoice_get(req)
        .then(res => {
          if (res) {
            this.invoice = res;
            this.invoice.patient.contact = _.mapKeys(this.invoice.patient.contact, (v, k) => _.snakeCase(k));
          } else {
            this.$refs.toast.showToast('Api Error', 'fa-solid fa-triangle-exclamation', 'error');
          }
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
      this.addProcedureCodeAndDescriptionIfValuesMatch();
      // this.updateStatusPayment();
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.header-cell-table {
  background-color: #cacdd6;
  color: black;
  font-weight: bold;
  text-align: center;
  white-space: normal;
  /* vertical-align: top; */
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: normal;
  margin: 5px;
  height: 40px;
}

.table-cell {
  word-wrap: break-word;
  vertical-align: top;
  text-align: center;
  border-bottom: 1px solid #d1d1d1;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  padding-left: 5px;
}

.table-cell-left {
  word-wrap: break-word;
  vertical-align: top;
  border-bottom: 1px solid #d1d1d1;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
}
</style>
