<template>
  <div>
    <div class="dashboard-content">
      <div class="claims-left-nav-section custom-scrollbar">
        <ClaimsLeftNav @set-subtitle="value => (subTitle = value)" :refresh="leftNavRefresh" />
      </div>

      <div id="claims-dashboard-table" class="claims-section pr-4">
        <!-- <DashboardNavigation /> -->

        <!-- nav section -->
        <div class="claim-header">
          <div class="claim-header-title">Claims</div>

          <div class="claim-search">
            <input
              v-model="searchString"
              @keyup.enter="search()"
              data-test-id="search-input"
              class="search-input"
              placeholder="Search by mrn, insurer, name, etc."
            />
            <div @click="clearSearch()" class="clear-search-btn">
              <i class="far fa-times-circle"></i>
            </div>
            <div data-test-id="search-icon" class="search-icon" @click="search()">
              <i class="fas fa-search !text-center !w-auto !h-auto"></i>
            </div>
          </div>

          <div class="consultant-search">
            <div style="clear: both"></div>

            <div class="float-left col-12 mt-2">
              <ListMedics
                id="claim-search-admitting-consultant"
                data-test-id="search-consult-input"
                v-model="admittingMedicString"
                placeholder="Search for Consultant"
                :enable_consultant="true"
                inputClass="consultant-typeahead"
              />
            </div>
          </div>

          <div @click="clearConsultant()" class="clear-contultants-btn">
            <i class="far fa-times-circle"></i>
          </div>

          <div class="add-new-claim-btn">
            <button
              @click="newClaimModal = true"
              type="button"
              data-test-id="add-claim-button"
              class="custom-btn-el inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              style="background: #00a7e1"
            >
              Add claim
            </button>
          </div>

          <div class="download-btn">
            <button
              @click="getClaimsCSV"
              type="button"
              class="custom-btn-el inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              style="background: #00a7e1"
            >
              Download CSV
            </button>
          </div>

          <div class="save-table-width">
            <i class="far fa-save text-xl" @click="savePreference()"></i>
            <div class="save-btn-description">Save table column width after resizing</div>
          </div>
        </div>
        <!-- nav section end -->

        <div v-if="subTitle" class="claim-sub-header">
          <span>{{ subTitle }}</span>
        </div>

        <div v-if="readyForApprovalMode">
          <button
            @click="signAllClaims()"
            type="button"
            class="custom-btn-el inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 float-left mr-2"
            style="background: #00a7e1"
            data-test-id="sign-all-claims"
          >
            Sign all claims
          </button>

          <div class="save-table-width float-left mt-2">
            <i class="fas fa-info-circle text-xl" style="cursor: pointer"></i>
            <div class="save-btn-description">Sign only claims listed on a page</div>
          </div>
        </div>

        <div style="clear: both"></div>

        <!-- swith to component || leave now for testing -->

        <div v-if="tableLayoutSettings && tableLoaded" class="cs-table-section">
          <div v-if="!claims || claims.length <= 0" class="no-claims-text">There are no claims of this type!</div>

          <div v-if="claims && claims.length > 0" class="cs-table flex">
            <div class="cs-li cs-tag cs-hide-resize">
              <div class="cs-title">
                <div class="select-wrapper">
                  <i
                    class="fa fa-refresh"
                    style="margin-top: 13px; margin-right: 5px; margin-left: 10px"
                    @click="resetSort()"
                  ></i>
                </div>
              </div>
              <div
                class="cs-item cs-tag-border"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                :style="{ background: findTagColor(item) }"
              ></div>
            </div>

            <!--
            <div class="cs-li cs-index" v-if="tableLayoutSettings[0].active" ref="csIndex" :style="{'width': tableLayoutSettings[0].width + 'px'}">
              <div class="cs-title">
                #
              </div>
              <div class="cs-item" v-for='(item, index) in claims' :key='index' :class="{ 'even-background': index%2 }">
                {{index}}
              </div>
            </div>
            -->

            <!-- 
            <Column customRef='csPatient' :columnWidth='tableLayoutSettings[1].width' name='PATIENT' :claims='claims' />
            -->

            <div
              class="cs-li cs-patient"
              v-if="tableLayoutSettings[1].active"
              ref="csPatient"
              :style="{ width: tableLayoutSettings[1].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">PATIENT</div>
                <div class="sort-icons" style="right: 24px">
                  <!-- Exemplo de uma sessão na tabela -->
                  <tr>
                    <td>
                      <i
                        class="fas fa-caret-up sort-up"
                        @click="sortList('patient.full_name', 'asc', 1)"
                        :class="{ blue: isUpIconBlue[1] }"
                      ></i>
                      <i
                        class="fas fa-caret-down sort-down"
                        @click="sortList('patient.full_name', 'desc', 1)"
                        :class="{ blue: isDownIconBlue[1] }"
                      ></i>
                    </td>
                    <!-- Restante das colunas da sessão -->
                  </tr>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                :ref="'test' + index"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.patient.full_name ? item.patient.full_name : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-mrn"
              v-if="tableLayoutSettings[2].active"
              ref="csMrn"
              :style="{ width: tableLayoutSettings[2].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">MRN</div>
                <div class="sort-icons" style="right: 20px">
                  <!-- Exemplo de uma sessão na tabela -->
                  <tr>
                    <td>
                      <i
                        class="fas fa-caret-up sort-up"
                        @click="sortList('patient.mrn', 'asc', 2)"
                        :class="{ blue: isUpIconBlue[2] }"
                      ></i>
                      <i
                        class="fas fa-caret-down sort-down"
                        @click="sortList('patient.mrn', 'desc', 2)"
                        :class="{ blue: isDownIconBlue[2] }"
                      ></i>
                    </td>
                    <!-- Restante das colunas da sessão -->
                  </tr>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.patient.mrn ? item.patient.mrn : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-appid"
              v-if="tableLayoutSettings[3].active"
              ref="csAppId"
              :style="{ width: tableLayoutSettings[3].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">APP ID</div>
                <div class="sort-icons" style="right: 5px">
                  <i
                    class="fas fa-caret-up sort-up"
                    @click="sortList('hospital_appointment_id', 'asc', 3)"
                    :class="{ blue: isUpIconBlue[3] }"
                  ></i>
                  <i
                    class="fas fa-caret-down sort-down"
                    @click="sortList('hospital_appointment_id', 'desc', 3)"
                    :class="{ blue: isDownIconBlue[3] }"
                  ></i>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.hospital_appointment_id ? item.hospital_appointment_id : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-los"
              v-if="tableLayoutSettings[4].active"
              ref="csLos"
              :style="{ width: tableLayoutSettings[4].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">LOS</div>
                <div class="sort-icons" style="right: 3px">
                  <i
                    class="fas fa-caret-up sort-up"
                    @click="sortList('length_of_stay_days', 'asc', 4, true)"
                    :class="{ blue: isUpIconBlue[4] }"
                  ></i>
                  <i
                    class="fas fa-caret-down sort-down"
                    @click="sortList('length_of_stay_days', 'desc', 4, true)"
                    :class="{ blue: isDownIconBlue[4] }"
                  ></i>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.length_of_stay_days ? item.length_of_stay_days : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-admitted"
              v-if="tableLayoutSettings[5].active"
              ref="csAdmitted"
              :style="{ width: tableLayoutSettings[5].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">ADMITTED</div>
                <div class="sort-icons" style="right: 5px">
                  <i
                    class="fas fa-caret-up sort-up"
                    @click="sortList('admitted_on', 'asc', 5)"
                    :class="{ blue: isUpIconBlue[5] }"
                  ></i>
                  <i
                    class="fas fa-caret-down sort-down"
                    @click="sortList('admitted_on', 'desc', 5)"
                    :class="{ blue: isDownIconBlue[5] }"
                  ></i>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.admitted_on ? formatDate(item.admitted_on) : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-discharged"
              v-if="tableLayoutSettings[6].active"
              ref="csDischarged"
              :style="{ width: tableLayoutSettings[6].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">DISCHARGED</div>
                <div class="sort-icons" style="right: 0px">
                  <i
                    class="fas fa-caret-up sort-up"
                    @click="sortList('discharged_on', 'asc', 6)"
                    :class="{ blue: isUpIconBlue[6] }"
                  ></i>
                  <i
                    class="fas fa-caret-down sort-down"
                    @click="sortList('discharged_on', 'desc', 6)"
                    :class="{ blue: isDownIconBlue[6] }"
                  ></i>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.discharged_on ? formatDate(item.discharged_on) : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-insurer"
              v-if="tableLayoutSettings[7].active"
              ref="csInsurer"
              :style="{ width: tableLayoutSettings[7].width + 'px' }"
            >
              <div class="cs-title">INSURER</div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.insurance.insurer_name ? item.insurance.insurer_name : '/' }} <i>{{ item.insurer_cf }}</i>
              </div>
            </div>

            <div
              class="cs-li cs-consultant"
              v-if="tableLayoutSettings[8].active"
              ref="csConsultant"
              :style="{ width: tableLayoutSettings[8].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">CONSULTANT</div>
                <div class="sort-icons" style="right: 16px">
                  <i
                    class="fas fa-caret-up sort-up"
                    @click="sortList('claim_consultant.full_name', 'asc', 8)"
                    :class="{ blue: isUpIconBlue[8] }"
                  ></i>
                  <i
                    class="fas fa-caret-down sort-down"
                    @click="sortList('claim_consultant.full_name', 'desc', 8)"
                    :class="{ blue: isDownIconBlue[8] }"
                  ></i>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.claim_consultant.full_name ? item.claim_consultant.full_name : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-hosvalue"
              v-if="tableLayoutSettings[14].active"
              ref="csHosValue"
              :style="{ width: tableLayoutSettings[14].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">€ HOS</div>
                <div class="sort-icons" style="right: 22px">
                  <i
                    class="fas fa-caret-up sort-up"
                    @click="sortList('claim_hospital_value', 'asc', 14, true)"
                    :class="{ blue: isUpIconBlue[14] }"
                  ></i>
                  <i
                    class="fas fa-caret-down sort-down"
                    @click="sortList('claim_hospital_value', 'desc', 14, true)"
                    :class="{ blue: isDownIconBlue[14] }"
                  ></i>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.claim_hospital_value ? item.claim_hospital_value : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-consvalue"
              v-if="tableLayoutSettings[15].active"
              ref="csConsValue"
              :style="{ width: tableLayoutSettings[15].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">€ INV</div>
                <div class="sort-icons" style="right: 19px">
                  <i
                    class="fas fa-caret-up sort-up"
                    @click="sortList('claim_consultant_value', 'asc', 15, true)"
                    :class="{ blue: isUpIconBlue[15] }"
                  ></i>
                  <i
                    class="fas fa-caret-down sort-down"
                    @click="sortList('claim_consultant_value', 'desc', 15, true)"
                    :class="{ blue: isDownIconBlue[15] }"
                  ></i>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.claim_consultant_value ? item.claim_consultant_value : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-invpaidvalue"
              v-if="tableLayoutSettings[16].active"
              ref="csInvPaidValue"
              :style="{ width: tableLayoutSettings[16].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">€ PAID</div>
                <div class="sort-icons" style="right: 19px">
                  <i
                    class="fas fa-caret-up sort-up"
                    @click="sortList('claim_paid_value', 'asc', 16, true)"
                    :class="{ blue: isUpIconBlue[16] }"
                  ></i>
                  <i
                    class="fas fa-caret-down sort-down"
                    @click="sortList('claim_paid_value', 'desc', 16, true)"
                    :class="{ blue: isDownIconBlue[16] }"
                  ></i>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.claim_paid_value ? item.claim_paid_value : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-invno"
              v-if="tableLayoutSettings[17].active"
              ref="csInvNo"
              :style="{ width: tableLayoutSettings[17].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">INV NO</div>
                <div class="sort-icons" style="right: 19px">
                  <i
                    class="fas fa-caret-up sort-up"
                    @click="sortList('invoice_no', 'asc', 17)"
                    :class="{ blue: isUpIconBlue[17] }"
                  ></i>
                  <i
                    class="fas fa-caret-down sort-down"
                    @click="sortList('invoice_no', 'desc', 17)"
                    :class="{ blue: isDownIconBlue[17] }"
                  ></i>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.invoice_no ? item.invoice_no : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-status"
              v-if="tableLayoutSettings[9].active"
              ref="csStatus"
              :style="{ width: tableLayoutSettings[9].width + 'px' }"
            >
              <div class="cs-title">
                <div class="sort-title">STATUS</div>
                <div class="sort-icons" style="right: 26px">
                  <i
                    class="fas fa-caret-up sort-up"
                    @click="sortList('claim_state_str', 'asc', 9)"
                    :class="{ blue: isUpIconBlue[9] }"
                  ></i>
                  <i
                    class="fas fa-caret-down sort-down"
                    @click="sortList('claim_state_str', 'desc', 9)"
                    :class="{ blue: isDownIconBlue[9] }"
                  ></i>
                </div>
              </div>
              <div
                class="cs-item"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                @click="openManageModal(item)"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                {{ item.claim_state_str ? item.claim_state_str : '/' }}
              </div>
            </div>

            <div
              class="cs-li cs-note"
              v-if="tableLayoutSettings[10].active"
              ref="csNote"
              :style="{ width: 'min-content' }"
            >
              <div class="cs-title">NOTE</div>
              <div
                class="cs-item cs-note"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
                style="overflow: visible"
                @mouseover="selectRow(index)"
                @mouseleave="deselectRow(index)"
              >
                <span v-if="!item.latest_note && !item.latest_note_date"> / </span>
                <div v-if="item.latest_note && item.latest_note_date" class="note-icon">
                  <span>{{ item.latest_note }}</span>
                  <span class="text-medosync-inactive-grey ml-1">({{ formatDate(item.latest_note_date) }})</span>
                </div>
              </div>
            </div>

            <div v-if="readyForApprovalMode" class="cs-li cs-action" style="resize: none">
              <div class="cs-title">Sign</div>
              <div
                class="cs-item-btn pl-3 pr-1"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
              >
                <div @click="signClaim(item, true)" class="cs-btn-review" :data-test-id="`sign-${item.claim_id}`">
                  Sign
                </div>
              </div>
            </div>

            <div v-if="readyForApprovalMode" class="cs-li cs-action" style="resize: none">
              <div class="cs-title">Reject</div>
              <div
                class="cs-item-btn pl-1 pr-3"
                v-for="(item, index) in claims"
                :key="index"
                :class="{ 'even-background': index % 2 }"
              >
                <div
                  @click="consultantRejectClaim(item)"
                  class="cs-btn-review"
                  style="background: #e76f51"
                  :data-test-id="`reject-${item.claim_id}`"
                >
                  Reject
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="clear: both"></div>

        <div v-if="claims && claims.length > 0 && tableLoaded">
          <div class="pagination">
            <div
              class="pagination-btn"
              :class="{ 'pag-btn-disabled': start <= 1 }"
              style="border-top-left-radius: 8px; border-bottom-left-radius: 8px"
              @click="
                pageCounter--;
                paginationPoints(-this.size), getClaims();
              "
            >
              <i class="fas fa-chevron-left" style="padding-right: 3px"></i> Prev
            </div>
            <div class="pagination-btn-el" style="border-left: 0; border-right: 0">
              {{ pageCounter * 20 + claims.length }}
            </div>
            <div class="pagination-btn-el" style="border-left: 0; border-right: 0; padding: 0">/</div>
            <div class="pagination-btn-el" style="border-left: 0; border-right: 0">
              {{ totalItems }}
            </div>
            <div
              class="pagination-btn"
              :class="{ 'pag-btn-disabled': end >= totalItems }"
              style="border-top-right-radius: 8px; border-bottom-right-radius: 8px"
              @click="
                pageCounter++;
                paginationPoints(this.size);
                getClaims();
              "
            >
              Next <i class="fas fa-chevron-right" style="padding-left: 3px"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add new claim modal -->
    <div class="new-claim-modal-section">
      <NewClaim v-model="newClaimModal" />
    </div>
  </div>
  <SpinnerLoader v-if="isLoading" />
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import { state_store } from '@/store';
import { userStore } from '@/store/user';
import { OpenAPIJSON, OpenAPICSV } from 'api/openapi';
import { ConsultantClaimStateEnum } from 'enums';
import StringUtility from 'utils/stringUtility';
import ClaimsLeftNav from '@/components/dashboard/Claims-left-nav.vue';
//import Column from '@/components/dashboard/Column'
//import DashboardNavigation from '@/components/dashboard/Dashboard-navigation'
// modals
import NewClaim from '@/components/modal/NewClaim.vue';
// functions
import { generateClaimLink } from '@/util/claims/generateClaimLink';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'Claims-list',
  components: {
    ListMedics,
    ClaimsLeftNav,
    NewClaim,
    SpinnerLoader,
    ToastComponent
    //Column,
    //DashboardNavigation
  },
  data() {
    return {
      claims: null,
      colapsed: false,
      start: 1,
      end: 20,
      size: 20,
      totalItems: 0,
      tableLayoutSettings: null,
      searchString: '',
      subTitle: '',
      //sorts
      sort: null,
      newClaimModal: false,
      selectedAppointment: null,
      totalTableWidth: 500,
      modalStateKey: 0,
      tableLoaded: false,
      pageCounter: 0,
      admittingMedicString: '',
      selectedContultantId: null,
      // Set true if the logged in user is a consultant
      isConsultant: false,
      isLoading: false,

      // The ID of the consultant ID who the claims-list is being viewed by.  This could
      // be a list of several IDs if the logged-in user is a claims billing admin.
      viewingConsultantConsultantIds: [],
      leftNavRefresh: 0,
      isUpIconBlue: [],
      isDownIconBlue: [],

      default_table_layout: [
        { name: 'Index', active: true, width: 35 },
        { name: 'Patient', active: true, width: 130 }, // [1]
        { name: 'MRN', active: true, width: 100 }, // [2]
        { name: 'AppID', active: true, width: 120 }, // [3]
        { name: 'LOS', active: true, width: 60 }, // [4]
        { name: 'Admitted', active: true, width: 100 }, // [5]
        { name: 'Discharged', active: true, width: 100 }, // [6]
        { name: 'Insurer', active: true, width: 130 }, // [7]
        { name: 'Invoicing Consultant', active: userStore.getters.user?.is_medic ? false : true, width: 140 }, // [8]
        { name: 'Status', active: true, width: 130 }, // [9]
        { name: 'Note', active: true, width: 220 }, // [10]
        { name: 'Tag', active: true, width: 100 }, // [11]
        { name: 'Manage', active: true, width: 120 }, // [12]
        { name: 'Action', active: true, width: 100 }, // [13]
        { name: 'Hospital Claim Value', active: true, width: 120 }, // [14]
        { name: 'Consultant Claims Value', active: true, width: 120 }, // [15]
        { name: 'Consultant Claim Paid Value', active: false, width: 120 }, // [16]
        { name: 'Consultant Claims Invoice Number', active: false, width: 130 } // [17]
      ],
      readyForApprovalMode: false,
      hoverColor: 'rgba(0, 167, 225, 0.5)',
      refList: [
        'csPatient',
        'csMrn',
        'csAppId',
        'csLos',
        'csAdmitted',
        'csDischarged',
        'csInsurer',
        'csConsultant',
        'csHosValue',
        'csConsValue',
        'csInvPaidValue',
        'csInvNo',
        'csStatus',
        'csNote'
      ]
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.pageCounter = 0;
        if (this.$route.params.filter === 'ready_for_consultant_approval') {
          this.readyForApprovalMode = true;
        } else {
          this.readyForApprovalMode = false;
        }
        this.getClaims();
      }
    },
    selectedContultantId: {
      handler: function () {
        this.pageCounter = 0;
        this.getClaims();
      }
    },
    admittingMedicString: {
      handler: function () {
        this.pageCounter = 0;
        this.getClaims();
      }
    }
  },
  methods: {
    paginationPoints(size) {
      this.start = this.start + size;
      this.end = this.end + size;
    },
    sortFilter() {
      this.size = 'asc:full_name';
    },
    getNestedValue(obj, path) {
      const keys = path.split('.');
      let value = obj;

      for (const key of keys) {
        if (value && key in value) {
          value = value[key];
        } else {
          return undefined;
        }
      }

      return value;
    },
    sortList(atribute, direction, sessionIndex, setToNumber) {
      if (atribute !== undefined) {
        this.claims.sort((a, b) => {
          let valueA = this.getNestedValue(a, atribute);
          let valueB = this.getNestedValue(b, atribute);

          if (setToNumber) {
            valueA = Number(valueA);
            valueB = Number(valueB);
          }

          if (typeof valueA === 'number' && typeof valueB === 'number') {
            return direction === 'asc' ? valueA - valueB : valueB - valueA;
          } else if (typeof valueA === 'string' && typeof valueB === 'string') {
            return direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
          } else {
            // Handle other cases if needed
          }
        });
      }

      for (let i = 0; i < this.isUpIconBlue.length; i++) {
        if (i !== sessionIndex) {
          this.isUpIconBlue[i] = false;
          this.isDownIconBlue[i] = false;
        }
      }
      if (direction === 'asc') {
        this.isUpIconBlue[sessionIndex] = true;
        this.isDownIconBlue[sessionIndex] = false;
      } else {
        this.isUpIconBlue[sessionIndex] = false;
        this.isDownIconBlue[sessionIndex] = true;
      }
      //this.getClaims()
    },
    resetSort() {
      this.sort = null;
      this.sortList();
      this.getClaims();
    },
    async _getClaims(oapi) {
      // search filter
      if (this.searchString === '') {
        this.searchString = null;
      }

      // claim state filter
      let stateFilter;
      if (this.$route.params.filter) {
        if (this.$route.params.filter === 'all') {
          stateFilter = null;
        } else if (this.$route.params.filter === ConsultantClaimStateEnum.DRAFT) {
          stateFilter = [ConsultantClaimStateEnum.DRAFT, ConsultantClaimStateEnum.CREATED];
        } else {
          stateFilter = [this.$route.params.filter.toLowerCase()];
        }
      }

      // claim tags filter.  Tags are case sensitive
      let tagFilter;
      if (this.$route.params.tag) {
        if (this.$route.params.tag === 'all') {
          tagFilter = null;
        } else {
          tagFilter = [this.$route.params.tag];
        }
      }

      let tagConsultant = null;
      if (this.selectedContultantId) tagConsultant = parseInt(this.selectedContultantId);
      if (this.admittingMedicString) tagConsultant = parseInt(this.admittingMedicString.id);

      const pagination =
        oapi instanceof OpenAPIJSON
          ? {
              start_idx: this.start,
              end_idx: this.end,
              page_size: null
            }
          : null;

      return await oapi.searchClaims(
        null,
        null,
        null,
        null,
        null,
        this.searchString,
        null,
        null,
        null,
        null,
        tagConsultant,
        stateFilter,
        tagFilter,
        this.sort,
        pagination
      );
    },
    async getClaims() {
      this.isLoading = true;
      const oapi = new OpenAPIJSON();
      let response;
      try {
        response = await this._getClaims(oapi);
      } catch {
        this.$refs.toast.showToast(
          'There was an issue with the server. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
        this.isLoading = false;
      }

      if (response && response.items && response.total_items) {
        this.setTable();
        this.claims = response.items;
        this.totalItems = response.total_items;
        setTimeout(this.resizeTable, 700);
      } else {
        this.setTable();
        this.claims = response.items;
        this.totalItems = 0;
        setTimeout(this.resizeTable, 700);
      }
      this.isLoading = false;
    },
    async getClaimsCSV() {
      const oapi = new OpenAPICSV('ClaimsList.csv');
      await this._getClaims(oapi);
    },
    formatDate(data) {
      return StringUtility.formatDate(data);
    },
    async init() {
      this.isConsultant = userStore.getters.user?.is_consultant;
      // Table layout might not exist for all users
      let table_layout = null;
      let preferences = userStore.getters?.user?.preferences;
      if (preferences && preferences.length > 0) {
        let data = JSON.parse(preferences);
        if (data.tableLayout) {
          table_layout = data.tableLayout;
        }
      }

      if (!table_layout) {
        //  console.log("No table layout definition exists for the user.  Defining a new one.");
        this.tableLayoutSettings = this.default_table_layout;
      } else if (table_layout.length !== this.default_table_layout.length) {
        //  console.log("Table layout for user does not match the struct size of the default.  Using defaults.")
        this.tableLayoutSettings = this.default_table_layout;
      } else {
        this.tableLayoutSettings = table_layout;
      }

      // Affects the side-bar claim/tags counts.
      if (userStore.getters.user?.associate_consultant_ids) {
        this.viewingConsultantConsultantIds = userStore.getters.user?.associate_consultant_ids;
      } else {
        this.viewingConsultantConsultantIds = [userStore.getters.user?.hospital_medic_id];
      }
    },
    search() {
      this.getClaims();
    },
    refreshAppointmentList() {
      this.newClaimModal = false;
      this.getClaims();
    },
    openManageModal(item) {
      this.$router.push({ name: 'ConsultantClaimsDetails', params: { id: item.claim_consultant_id } });
    },
    async updateAppointmentList(appointment) {
      const oapi = new OpenAPIJSON();
      let response = await oapi.searchClaims(appointment.claim_id);
      if (response?.status && response?.status >= 300) {
        this.$refs.toast.showToast(response.data.status.reason, 'fa-solid fa-triangle-exclamation', 'error');
      } else {
        // This should be a list of just one.
        const updated_claim = response.items[0];
        generateClaimLink(updated_claim);
        const app =
          this.selectedAppointment === undefined || this.selectedAppointment === null
            ? appointment
            : this.selectedAppointment;
        if (app !== null && app.claim_state !== updated_claim.claim_state) {
          // When the claim state changes, the claim needs to be removed from the current
          // claims table.
          const index = this.claims.indexOf(appointment);
          if (index > -1) {
            this.claims.splice(index, 1);
          }
        }
      }
      this.refreshLeftNav();
    },
    refreshLeftNav() {
      // Force refresh of left-navigator to update claim counts.
      this.leftNavRefresh++;
    },
    review(item) {
      this.$router.push(item.link);
    },
    savePreference() {
      // take width of columns from DOM
      this.tableLayoutSettings[0].width = this.$refs.csIndex.clientWidth;
      this.tableLayoutSettings[1].width = this.$refs.csPatient.clientWidth;
      this.tableLayoutSettings[2].width = this.$refs.csMrn.clientWidth;
      this.tableLayoutSettings[3].width = this.$refs.csAppId.clientWidth;
      this.tableLayoutSettings[4].width = this.$refs.csLos.clientWidth;
      this.tableLayoutSettings[5].width = this.$refs.csAdmitted.clientWidth;
      this.tableLayoutSettings[6].width = this.$refs.csDischarged.clientWidth;
      this.tableLayoutSettings[7].width = this.$refs.csInsurer.clientWidth;
      this.tableLayoutSettings[8].width = this.$refs.csConsultant.clientWidth;
      this.tableLayoutSettings[9].width = this.$refs.csStatus.clientWidth;
      this.tableLayoutSettings[10].width = this.$refs.csNote.clientWidth;
      this.tableLayoutSettings[14].width = this.$refs.csHosValue.clientWidth;
      this.tableLayoutSettings[15].width = this.$refs.csConsValue.clientWidth;
      this.tableLayoutSettings[16].width = this.$refs.csInvPaidValue.clientWidth;
      this.tableLayoutSettings[17].width = this.$refs.csInvNo.clientWidth;

      this.resizeTable();
      this.updatePreferences();
    },
    resizeTable() {
      let width = 0;

      this.tableLayoutSettings.forEach(item => {
        if (item.active) {
          width += parseInt(item.width);
        }
      });
      this.totalTableWidth = width;
      this.tableLoaded = true;
    },
    updatePreferences() {
      let ls = JSON.parse(localStorage.getItem('preferences'));
      ls.tableLayout = this.tableLayoutSettings;
      localStorage.preferences = JSON.stringify(ls);
      this.saveUserPreferences(ls);
    },
    async saveUserPreferences(data) {
      const oapi = new OpenAPIJSON();

      let response = await oapi.saveUserPreferences(JSON.stringify(data));

      if (response?.status && response?.status >= 300) {
        this.$refs.toast.showToast(response.data.status.reason, 'fa-solid fa-triangle-exclamation', 'error');
      } else {
        this.$refs.toast.showToast('Menu preference successfully saved!', 'fas fa-check', 'success');
      }
    },
    tagsUpdate(tag, action = 'add') {
      if (action == 'add') {
        this.selectedAppointment.tags.push(tag);
      } else {
        this.selectedAppointment.tags.splice(this.selectedAppointment.tags.indexOf(tag), 1);
      }
    },
    findTagColor(claim) {
      if (claim.tags.length <= 0) {
        return '';
      }

      let color = '#00A7E1';
      // find tag
      let ls = JSON.parse(localStorage.getItem('preferences'));
      ls?.tagsLayout?.forEach(tag => {
        if (claim.tags[0].toLowerCase() == tag.name.toLowerCase()) {
          if (tag.color) {
            color = tag.color;
          }
        }
      });
      return color;
    },
    async updateClaimFlags(appointment, flag_name, flag_value) {
      if (flag_name === 'requiresClaimform') {
        appointment.claim_requires_claimform = flag_value;
      }
      const oapi = new OpenAPICSV();
      await oapi.claimSetFeatures(
        appointment.claim_id,
        appointment.claim_requires_claimform,
        appointment.claim_requires_invoice
      );
    },
    clearSearch() {
      if (this.searchString != null) {
        this.searchString = null;
        this.getClaims();
      }
    },
    clearConsultant() {
      this.selectedContultantId = null;
      this.admittingMedicString = '';
      this.getClaims();
    },
    async signClaim(item, refreshPage = true) {
      const oapi = new OpenAPIJSON();
      let req = {
        claim_ref: {
          type: 'id',
          id: item.claim_consultant_id
        },
        consultant: {
          type: 'id',
          id: item.claim_consultant.consultant_id
        },
        reason: 'Request through UI',
        target_state: 'consultant_approved'
      };
      await oapi
        .consultant_state_change(req)
        .then(res => {
          if (res) {
            this.$refs.toast.showToast(' Consultant Approved', 'fas fa-check', 'success');
          } else {
            this.$refs.toast.showToast('fa-solid fa-triangle-exclamation', 'error');
          }
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      if (refreshPage) {
        this.getClaims();
        this.refreshLeftNav();
      }
    },
    async signAllClaims() {
      for (let idx = 0; idx < this.claims.length; idx++) {
        await this.signClaim(this.claims[idx], false);
      }
      this.getClaims();
      this.refreshLeftNav();
    },
    async consultantRejectClaim(item) {
      const oapi = new OpenAPIJSON();
      let req = {
        claim_ref: {
          type: 'id',
          id: item.claim_consultant_id
        },
        consultant: {
          type: 'id',
          id: item.claim_consultant.consultant_id
        },
        reason: 'Request through UI',
        target_state: 'consultant_rejected'
      };
      await oapi
        .consultant_state_change(req)
        .then(res => {
          if (res) {
            this.$refs.toast.showToast('Claim marked as rejected', 'fas fa-check', 'success');
          } else {
            this.$refs.toast.showToast('fa-solid fa-triangle-exclamation', 'error');
          }
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
      this.getClaims();
      this.refreshLeftNav();
    },
    selectRow(index) {
      this.refList.map(item => {
        var hasChildren = 0;
        try {
          if (this.$refs[item].children) {
            hasChildren = 0;
          }
        } catch (err) {
          hasChildren = 1;
          // console.log('err', err);
        }
        if (hasChildren == 0) {
          this.$refs[item].children[index + 1].style.backgroundColor = this.hoverColor;
        }
      });
    },
    deselectRow(index) {
      let bgColor;
      if (index % 2) {
        bgColor = 'rgba(17, 28, 78, 0.07)';
      } else {
        bgColor = 'transparent';
      }

      this.refList.map(item => {
        var hasChildren = 0;
        try {
          if (this.$refs[item].children) {
            hasChildren = 0;
          }
        } catch (err) {
          hasChildren = 1;
          //console.log('err', err);
        }
        if (hasChildren == 0) {
          this.$refs[item].children[index + 1].style.backgroundColor = bgColor;
        }
      });
    },
    setTable() {
      // setting € PAID and INV NO columns
      if (this.isPaidAndInvNoColumnActive()) {
        this.tableLayoutSettings[16].active = true;
        this.tableLayoutSettings[17].active = true;
      } else {
        this.tableLayoutSettings[16].active = false;
        this.tableLayoutSettings[17].active = false;
      }
      // setting STATUS column
      if (this.$route.params.filter === 'all') {
        this.tableLayoutSettings[9].active = true;
      } else {
        this.tableLayoutSettings[9].active = false;
      }
      this.resizeTable();
    },
    isPaidAndInvNoColumnActive() {
      const claimState = this.$route.params.filter;
      if (
        claimState === ConsultantClaimStateEnum.DRAFT ||
        claimState === ConsultantClaimStateEnum.READY_FOR_CONSULTANT_APPROVAL ||
        claimState === ConsultantClaimStateEnum.REJECTED_BY_CONSULTANT_FOR_AMENDMENT ||
        claimState === ConsultantClaimStateEnum.CONSULTANT_APPROVED
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  async mounted() {
    // Must fetch and wait on this data because other calls rely on the structures in it.
    await this.init();

    // Ensure that the Manage Claim re-opens unless the user has explicitly closed the modal.
    this.selectedAppointment = state_store.dashboardModalClaimOpen;

    this.getClaims();
  }
};

/* styles */
import '@/assets/scss/claim-list/index.scss';
import ListMedics from '@/components/form-fields/List-medics.vue';
</script>

<style>
.blue {
  color: blue;
}
</style>
