<template>
  <div class="flex flex-col h-screen p-8">
    <router-link :to="`/access/admission/${appointmentId}`" class="flex items-center mb-5">
      <i class="fas fa-chevron-left mr-2"></i>
      <span>back to table “All Appointments”</span>
    </router-link>

    <Breadcrumbs :links="breadcrumbs" />

    <SpinnerLoader v-if="isLoading" />
    <template v-else>
      <AdmissionDetailsHeader
        v-if="appointmentInfo"
        class="mt-5"
        :appointment="appointmentInfo"
        :hospital-name="fetchedClaimformData?.hospital_name"
        :isEditMode="formEditMode"
        :claimFormId="currentLocatorInfo?.claim_form_id"
        :claimPatientId="selectedClaimPatientId"
        :claimFormType="selectedClaimFormType"
        :fetchedClaimformData="fetchedClaimformData"
        :claim-section-warnings="claimSectionWarnings"
        @refresh-data="refreshData"
        @scroll-to-field="onScrollToField"
        @change-pre-admit-state="changePreAdmitState"
      />

      <TabNav
        v-if="!formEditMode"
        :navs="navs"
        :selected="selectedTab"
        @switch-tab="switchTab"
        :max-items="2"
        :show-form-warning="showFormWarning"
      />
      <AdmissionClaimForm
        ref="patientForm"
        :hospital_name="fetchedClaimformData?.hospital_name"
        v-if="selectedTab === 'patientForm' && claimformData"
        :claimform="claimformData"
        :claim-section-warnings="claimSectionWarnings"
        :signature="patientSignature"
        :disableEditMode="true"
        @edit-mode="value => (formEditMode = value)"
        @set-signature="setAppointmentSignature"
        @update-patient-claimform="updatePatientClaimform"
        @generate-pdf="generatePdf"
        @refresh-data="refreshData"
      />
      <AdmissionAdditionalAttachments
        v-if="selectedTab === 'attachments'"
        :files="files"
        :appointmentId="appointmentId"
        @remove-file-from-list="removeFileFromList"
        @refetch-files="listFiles"
      />
    </template>

    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import _ from 'lodash';
import { nextTick } from 'vue';
import { OpenAPIJSON, OpenAPIPDF } from 'api/openapi';
import { templateMessageStore } from '@/store/templateMessage';
import { AssocType, TemplateMessageType } from 'enums';
import { mapFormValuesFromGet, mapFormValuesForUpdate, updateCombinedValues } from 'utils';

import Breadcrumbs from '@/views/claims/details/Breadcrumbs.vue';
import AdmissionDetailsHeader from '../../components/admission/AdmissionDetailsHeader.vue';
import TabNav from '../claims/details/TabNav.vue';
import AdmissionClaimForm from './AdmissionClaimForm.vue';
import AdmissionAdditionalAttachments from '../../components/admission/AdmissionAdditionalAttachments.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';

export default {
  name: 'AdmissionDetailsPage',
  components: {
    Breadcrumbs,
    AdmissionDetailsHeader,
    TabNav,
    AdmissionClaimForm,
    AdmissionAdditionalAttachments,
    ToastComponent,
    SpinnerLoader
  },
  data() {
    return {
      isLoading: true,
      formEditMode: false,
      openApi: new OpenAPIJSON(),
      openApiPdf: new OpenAPIPDF(),
      appointmentId: parseInt(this.$route.params.id),
      breadcrumbs: [{ name: 'All Appointments', to: '/access/admission/' + this.$route.params.id }, { name: '' }],

      appointmentInfo: null,
      fetchedClaimformData: null,
      claimformData: null,
      selectedClaimPatientId: null,
      selectedClaimFormType: null,
      navs: [
        {
          type: 'patientForm',
          name: 'Patient Form',
          number: null,
          dataIdTest: 'patient-form-type',
          visible: true
        },
        {
          type: 'attachments',
          name: 'Claim Documents',
          number: 0,
          dataIdTest: 'attachments-tab',
          visible: true
        }
      ],
      selectedTab: 'patientForm',
      claimSectionWarnings: [
        /* {
          title: 'Missing Quote Policy Number',
          description: 'The quote policy number is required for this claim form.',
          section: 'vhi-hospital-policy-details',
          id: 'policy-no'
        },
        {
          title: 'Missing Patient Name',
          description: 'The patient name is required for this claim form.',
          section: 'vhi-hospital-policy-details',
          id: 'patient-name'
        },
        {
          title: 'Missing Signature',
          description: 'The claim form must be signed by the patient.',
          section: 'signature-pad-element-title',
          id: 'signature-pad-element'
        } */
      ],
      files: [],
      currentLocatorInfo: null
    };
  },
  computed: {
    showFormWarning() {
      return this.claimSectionWarnings.length > 0;
    },
    //Just for testing
    claimFormType() {
      let claimFormType = this.fetchedClaimformData?.claim_form_type;
      if (!claimFormType) return 'unknown';

      switch (claimFormType) {
        case 'vhi_hospital':
          return 'vhi-hospital';
        case 'laya_inpatient':
          return 'laya';

        default:
          return claimFormType;
      }
    }
  },
  created() {
    templateMessageStore.dispatch('getMessageTemplate', TemplateMessageType.PATIENT_REGISTRATION_SMS);
    templateMessageStore.dispatch('getMessageTemplate', TemplateMessageType.PATIENT_REGISTRATION_EMAIL);
  },
  async mounted() {
    document.title = 'Access';
    await this.getAllClaims();
    await this.listFiles();
    await this.getAppointmentDetails();
  },
  beforeUnmount() {
    document.title = 'Navigator';
  },
  methods: {
    switchTab(type) {
      this.selectedTab = type;
    },
    onScrollToField(elementId) {
      if (this.selectedTab !== 'patientForm') this.selectedTab = 'patientForm';

      nextTick(() => {
        this.$refs.patientForm.scrollToField(elementId);
      });
    },

    async refreshData() {
      this.fetchedClaimformData = null;
      this.claimformData = null;
      this.claimSectionWarnings = [];
      this.appointmentInfo = null;
      await this.getAllClaims();
      await this.listFiles();
      await this.getAppointmentDetails();
    },

    async getAllClaims() {
      try {
        this.loading = true;
        const { claim_form_list } = await this.openApi.claimform_patient_list(this.appointmentId);
        if (claim_form_list.length > 0) {
          this.currentLocatorInfo = claim_form_list[0].locator;
          this.selectedClaimPatientId = claim_form_list[0].claim_patient_id;
          this.selectedClaimFormType = claim_form_list[0].claim_form_type;

          await this.getPatientClaimform(claim_form_list[0]);
          await this.getAppointmentSignature(claim_form_list[0].locator);
        }
      } catch (error) {
        this.showErrorMessage('Something went wrong getting appointment details. Please try again and refresh page.');
      } finally {
        this.isLoading = false;
      }
    },
    async getAppointmentDetails() {
      try {
        const { items } = await this.openApi.appointment_search({
          appointment_id: this.appointmentId
        });
        this.appointmentInfo = items[0];
        this.breadcrumbs[1].name = `App ID ${this.appointmentInfo?.hospital_appointment_id}`;
      } catch {
        this.showErrorMessage('Something went wrong getting appointment details. Please try again and refresh page.');
      }
    },
    async getAppointmentSignature(locator) {
      if (!locator) {
        this.showErrorMessage('Something went wrong getting appointment signature. Please try again and refresh page.');
        return;
      } else {
        try {
          const { signature } = await this.openApi.signature_patient_get(locator);
          this.patientSignature = signature;
        } catch (error) {
          this.claimSectionWarnings.push({
            title: 'Missing Signature',
            description: 'The claim form must be signed by the patient.',
            section: 'signature-pad-element-title',
            id: 'signature-pad-element'
          });
          //this.showErrorMessage('Something went wrong getting signature. Please try again and refresh page.');
        }
      }
    },
    async setAppointmentSignature(signature) {
      if (signature && this.currentLocatorInfo) {
        try {
          await this.openApi.signature_patient_set(this.currentLocatorInfo, signature);
          this.patientSignature = signature;
        } catch (error) {
          this.showErrorMessage('Something went wrong setting signature. Please try again.');
        }
      }
    },
    async getPatientClaimform(locator) {
      if (!locator) {
        this.showErrorMessage('Something went wrong with data fetching. Please try again and refresh page.');
        return;
      } else {
        try {
          const claim_data = await this.openApi.claimform_get(
            locator.appointment_id,
            locator.claim_id,
            locator.locator.claim_form_id
          );
          this.fetchedClaimformData = claim_data;
          this.claimformData = mapFormValuesFromGet(this.fetchedClaimformData);
        } catch (error) {
          this.showErrorMessage('Something went wrong with data fetching. Please try again and refresh page.');
        }
      }
      this.getInputWarnings();
    },
    async updatePatientClaimform(data) {
      if (!data) {
        this.showErrorMessage('Something went wrong with data fetching. Please try again and refresh page.');
        return;
      } else {
        try {
          const mappedValues = mapFormValuesForUpdate(data);
          const combinedValues = _.merge(this.fetchedClaimformData, mappedValues);
          const updateValues = updateCombinedValues(combinedValues, mappedValues, data);
          await this.openApi.claimform_update(updateValues);
          this.$refs.toast.showToast('Form updated successfully', 'fas fa-check', 'success');
        } catch (error) {
          this.showErrorMessage('Something went wrong with data fetching. Please try again and refresh page.');
        }
      }
    },
    async listFiles() {
      try {
        const { files } = await this.openApi.file_list_by_id(parseInt(this.appointmentId), AssocType.Appointment);
        this.files = files;
        this.navs[1].number = files?.length;
      } catch (err) {
        this.showErrorMessage('Something went wrong getting files. Please try again and refresh page.');
      }
    },

    removeFileFromList(fileName) {
      this.files = this.files.filter(file => file.file_name !== fileName);
      this.navs[1].number = this.files?.length;
    },
    showErrorMessage(text) {
      this.$refs.toast.showToast(text, 'fa-solid fa-triangle-exclamation', 'error');
    },
    generatePdf() {
      this.openApiPdf.claimformPdfPreview(this.fetchedClaimformData);
    },
    getInputWarnings() {
      const form_prefix = this.getClaimFormPolicyElementId();
      if (this.fetchedClaimformData && this.fetchedClaimformData.patient) {
        //Irish Life Health
        if (this.fetchedClaimformData.insurance.insurer.ro.insurer_name === 'Irish Life Health') {
          if (
            this.fetchedClaimformData.insurance.membership_no1 === null ||
            this.fetchedClaimformData.insurance.membership_no1 === ''
          )
            this.createWarningObject("Patient's membership number", form_prefix + '-details', 'policy-no');
        }

        //VHI
        else if (this.fetchedClaimformData.insurance.insurer.ro.insurer_name === 'VHI') {
          if (
            this.fetchedClaimformData.insurance.membership_no1 === null ||
            this.fetchedClaimformData.insurance.membership_no1 === ''
          )
            this.createWarningObject('Quote Policy No.', form_prefix + '-details', 'policy-no');
        }

        //Laya
        else if (this.fetchedClaimformData.insurance.insurer.ro.insurer_name === 'Laya Healthcare') {
          if (
            this.fetchedClaimformData.insurance.membership_no1 === null ||
            this.fetchedClaimformData.insurance.membership_no1 === ''
          )
            this.createWarningObject('Membership Number', form_prefix + '-details', 'policy-no');
        }
        //Prison Officers
        else if (this.fetchedClaimformData.insurance.insurer.ro.insurer_name === 'Prison Officers') {
          if (
            (this.fetchedClaimformData.insurance.membership_no1 === null ||
              this.fetchedClaimformData.insurance.membership_no1 === '') &&
            (this.fetchedClaimformData.insurance.membership_no2 === null ||
              this.fetchedClaimformData.insurance.membership_no2 === '')
          )
            this.createWarningObject('Policy No.', form_prefix + '-details', 'policy-no');
        }

        //Garda & ESB
        else {
          if (
            this.fetchedClaimformData.insurance.membership_no1 === null ||
            this.fetchedClaimformData.insurance.membership_no1 === ''
          )
            this.createWarningObject('Membership/Staff Number', form_prefix + '-details', 'membership-no');
        }
      }
    },
    createWarningObject(fieldName, section, id) {
      this.claimSectionWarnings.push({
        title: 'Missing ' + fieldName,
        description: 'The ' + fieldName + ' is required for this claim form.',
        section: section,
        id: id
      });
    },
    getClaimFormPolicyElementId() {
      const claimType = this.claimformData.claim_form_type;
      switch (claimType) {
        case 'garda':
          return 'garda-membership';
        case 'e_s_b':
          return 'esb-membership';
        case 'prison_officers':
          return 'pomas-membership';
        case 'irish_life_hospital':
          return 'irish-life-membership';
        case 'vhi_hospital':
          return 'vhi-hospital-policy';
        case 'vhi_c_t':
          return 'vhi-ct-policy';
        case 'vhi_mri':
          return 'vhi-mri-policy';
        case 'vhi_oncology':
          return 'vhi-oncology-policy';
        case 'vhi_surgical':
          return 'vhi-surgical-policy';
        case 'laya_inpatient':
          return 'laya-policy';
        default:
          return '';
      }
    },
    changePreAdmitState(state) {
      this.appointmentInfo.preadmit_state = state;
    }
  }
};
</script>
