import { createRouter, createWebHistory, RouterView } from 'vue-router';

// auth
import Login from '@/views/auth/Login.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';

// main
import ClaimsList from '@/views/dashboard/Claims-list.vue';
import ConsultantClaimDetailsPage from '@/views/claims/details/ConsultantClaimDetailsPage.vue';
// Admission
import AdmissionDetailsPage from '@/views/admission/AdmissionDetailsPage.vue';
import AdmissionPage from '@/views/admission/AdmissionPage.vue';
// user management
import SettingsMainLayout from '@/views/user-management/SettingsMainLayout.vue';
import UserDashboard from '@/views/user-management/Dashboard.vue';
import DoctorsDashboard from '@/views/user-management/Doctors.vue';
import PatientsDashboard from '@/views/user-management/Patients.vue';
import HospitalsDashboard from '@/views/user-management/Hospitals.vue';
import GroupsDashboard from '@/views/user-management/Groups.vue';
import ProcedureCodes from '@/views/user-management/ProcedureCodes.vue';

// actions
import CreateUser from '@/views/user-management/CreateUser.vue';
import CreateGroup from '@/views/user-management/CreateGroup.vue';
import EditDoctor from '@/views/user-management/EditDoctor.vue';
import EditGroup from '@/views/user-management/EditGroup.vue';

// finance
import Finance from '@/views/finance/Finance.vue';

import ConsultantFiles from '@/views/finance/ConsultantFiles.vue';
import ReportingDashboard from '@/views/finance/ReportingDashboard.vue';
import ReportingBars from '@/views/finance/ReportingBars.vue';

// reporting
import InsurerSummary from '@/views/finance/reporting/InsurerSummary.vue';
import Submitted from '@/views/finance/reporting/Submitted.vue';
import ClaimsSignoff from '@/views/finance/reporting/ClaimsSignoff.vue';
import Aged from '@/views/finance/reporting/Aged.vue';
import Outstanding from '@/views/finance/reporting/Outstanding.vue';
import OutstandingInsurer from '@/views/finance/reporting/OutstandingInsurer.vue';
import OutstandingProcedure from '@/views/finance/reporting/OutstandingProcedure.vue';
import OutstandingPended from '@/views/finance/reporting/OutstandingPended.vue';
import OutstandingRejected from '@/views/finance/reporting/OutstandingRejected.vue';

import PaymentSummary from '@/views/finance/reporting/PaymentSummary.vue';
// notifications
import MainNotificationCentre from '@/views/notifications/MainNotificationCentre.vue';
// settings
import dashboardSettings from '@/views/settings/Dashboard-settings.vue';
import tagsManagement from '@/views/settings/Tags-settings.vue';
import menuManagement from '@/views/settings/Menu-settings.vue';
import CreateTag from '@/views/settings/CreateTag.vue';

//profile
import EditProfile from '@/views/profile/EditProfile.vue';
// components
import SideNav from '@/components/navigation/SideNav.vue';
//tests
import TestLayout from '@/views/tests/TestLayout.vue';
import CompositeClaimsList from '@/views/dashboard/CompositeClaimsList.vue';
import CompositeClaimDetailsPage from '@/views/claims/details/CompositeClaimDetailsPage.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  // navigator
  {
    path: '/navigator',
    name: 'Navigator',
    redirect: '/navigator/claims/draft/all',
    children: [
      // dashboard
      {
        path: '/navigator/claims/:filter/:tag',
        name: 'Claims-list',
        component: ClaimsList,
        props: true
      },
      // details
      {
        path: '/navigator/consclaim/details/:id',
        name: 'ConsultantClaimsDetails',
        component: ConsultantClaimDetailsPage
      },
      {
        path: '/navigator/compositeclaims/:filter/:tag',
        name: 'CompositeClaimsList',
        component: CompositeClaimsList,
        props: true
      },
      {
        path: '/navigator/compositeclaims/details/:id',
        name: 'CompositeClaimsDetails',
        component: CompositeClaimDetailsPage
      },

      //payments
      {
        path: '/navigator/finance',
        name: 'finance',
        component: RouterView,
        redirect: '/navigator/finance/payment-files',
        children: [
          {
            path: '/navigator/finance/payment-files',
            name: 'paymentFiles',
            component: Finance
          },
          {
            path: '/navigator/finance/files',
            name: 'consultantFiles',
            component: ConsultantFiles
          }
        ]
      },
      // reporting
      {
        path: '/navigator/finance/reporting',
        name: 'finance-reporting',
        component: RouterView,
        redirect: '/navigator/finance/reporting/dashboard',
        children: [
          {
            path: '/navigator/finance/reporting/dashboard',
            name: 'reportingDashboard',
            component: ReportingDashboard
          },
          {
            path: '/navigator/finance/reporting/submitted',
            name: 'submitted',
            component: Submitted
          },
          {
            path: '/navigator/finance/reporting/claims-signoff',
            name: 'claimsSignoff',
            component: ClaimsSignoff
          },
          {
            path: '/navigator/finance/reporting/aged',
            name: 'aged',
            component: Aged
          },
          {
            path: '/navigator/finance/reporting/outstanding',
            name: 'outstanding',
            component: Outstanding
          },
          {
            path: '/navigator/finance/reporting/outstanding-insurer',
            name: 'outstandingInsurer',
            component: OutstandingInsurer
          },
          {
            path: '/navigator/finance/reporting/outstanding-procedure',
            name: 'outstandingProcedure',
            component: OutstandingProcedure
          },
          {
            path: '/navigator/finance/reporting/outstanding-pended',
            name: 'outstandingPended',
            component: OutstandingPended
          },
          {
            path: '/navigator/finance/reporting/outstanding-rejected',
            name: 'outstandingRejected',
            component: OutstandingRejected
          },
          {
            path: '/navigator/finance/reporting/payment-summary',
            name: 'payment-summary',
            component: PaymentSummary
          },
          {
            path: '/navigator/finance/reporting/bars',
            name: 'reportingBars',
            component: ReportingBars
          },
          {
            path: '/navigator/finance/reporting/insurer-summary',
            name: 'insurerSummary',
            component: InsurerSummary
          }
        ]
      },
      //notifications
      {
        path: '/navigator/notifications/list',
        name: 'notifications',
        component: MainNotificationCentre,
        children: [
          {
            path: '/navigator/notifications/list/:id/:hospitalAppointmentId',
            name: 'MainNotificationCentre',
            component: MainNotificationCentre
          }
        ]
      }
    ]
  },
  // admission
  {
    path: '/access',
    name: 'Access',
    redirect: '/access/admission',
    children: [
      {
        path: '/access/admission/:id?',
        name: 'Admission',
        component: AdmissionPage
      },
      {
        path: '/access/admission/details/:id',
        name: 'AdmissionDetails',
        component: AdmissionDetailsPage
      }
    ]
  },
  // users managment
  {
    path: '/settings',
    name: 'settings',
    component: SettingsMainLayout,
    redirect: '/settings/claims-table',
    children: [
      {
        path: '/settings/claims-table',
        name: 'claimsTable',
        component: dashboardSettings
      },
      {
        path: '/settings/tags-management',
        name: 'tagsManagement',
        component: tagsManagement
      },
      {
        path: '/settings/menu-management',
        name: 'menuManagement',
        component: menuManagement
      },
      {
        path: '/settings/dashboard',
        name: 'User-dashboard',
        component: UserDashboard
      },
      {
        path: '/settings/manage-users',
        name: 'Doctors-dashboard',
        component: DoctorsDashboard
      },
      {
        path: '/settings/patients',
        name: 'Patients-dashboard',
        component: PatientsDashboard
      },
      {
        path: '/settings/hospitals',
        name: 'Hospitals-dashboard',
        component: HospitalsDashboard
      },
      {
        path: '/settings/create-user',
        name: 'Create-user',
        component: CreateUser
      },
      {
        path: '/settings/edit-doctor/:id',
        name: 'Edit-doctor',
        component: EditDoctor
      },
      {
        path: '/settings/groups',
        name: 'Groups',
        component: GroupsDashboard
      },
      {
        path: '/settings/create-group',
        name: 'Create-group',
        component: CreateGroup
      },
      {
        path: '/settings/edit-group/:id',
        name: 'Edit-group',
        component: EditGroup
      },
      {
        path: '/settings/procedure-codes',
        name: 'ProcedureCodes',
        component: ProcedureCodes
      },
      {
        path: '/settings/create-tag',
        name: 'CreateTag',
        component: CreateTag
      }
    ]
  },
  //profile
  {
    path: '/profile/edit',
    name: 'editProfile',
    component: EditProfile
  },
  // nav
  {
    path: '/sidenav',
    name: 'SideNav',
    component: SideNav
  },
  // test layout
  {
    path: '/test-layout',
    name: 'test-layout',
    component: TestLayout
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

export default router;
