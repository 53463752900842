<template>
  <div v-if="isDownloadClaimPackVisible" class="flex flex-col my-2">
    <button @click="handleDownloadZip" class="self-end btn-claim text-sm font-medium underline ml-1">
      <span class="icon"><i class="fa fa-download mr-2"></i></span>
      Download Claim Pack
    </button>
  </div>
  <div v-if="isSendToClaimInsurerVisible" class="flex flex-col my-2">
    <button @click="handleSendToClaimInsurer" class="self-end btn-claim1 text-sm font-medium underline ml-1">
      <span class="icon"><i class="far fa-paper-plane mr-2"></i></span>
      Submit to Insurer
    </button>
  </div>
  <div v-if="isSendForConsultantSignatureVisible" class="flex flex-col my-2">
    <button
      @click="handleSendClaimToConsultantForApproval"
      class="self-end btn-claim1 text-sm font-medium underline ml-1"
    >
      <span class="icon"><i class="fa-sharp fa-solid fa-file-signature mr-2"></i></span>
      Request Signature
    </button>
  </div>
  <div v-if="isRejectClaimVisible" class="flex flex-col my-2">
    <button @click="handleRejectClaim" class="self-end btn-claim justify-between text-sm font-medium underline ml-1">
      <span class="icon"><i class="fa fa-close"></i></span>
      <span class="grow">Reject Claim</span>
    </button>
  </div>
  <div v-if="isGetSignedVisible" class="flex flex-col my-2">
    <button @click="handleSignClaim" class="self-end btn-claim1 justify-between text-sm font-medium underline ml-1">
      <span class="icon"><i class="fa-sharp fa-solid fa-file-signature"></i></span>
      <span class="grow">Sign Claim</span>
    </button>
  </div>
  <div
    v-if="isCompleteClaimVisible"
    class="flex flex-col my-2"
  >
    <button
      @click="handleCompleteClaim"
      class="self-end btn-claim1 text-sm font-medium underline ml-1"
    >
      <span class="icon"><i class="fa-solid fa-check mr-2"></i></span>
      Complete Claim
    </button>
  </div>
  <div v-if="isResendToClaimInsurerVisible" class="flex flex-col my-2">
    <button @click="handeResendToSignature()" class="self-end btn-claim1 text-xs font-medium ml-1">
      <span class="icon"><i class="fa-solid fa-file-signature"></i></span>
      Re-submit to Insurer
    </button>
  </div>

  <div
    v-if="filteredDetails && !isReadytoSignByConsultantVisible && !isResendToClaimInsurerVisible"
    class="flex flex-col my-2"
  ></div>

  <div v-if="filteredDetails && isReadytoSignByConsultantVisible" class="flex flex-col my-2">
    <button
      v-if="filteredDetails.length !== 0"
      class="self-end btn-claim1 text-sm font-medium underline ml-1 opacity-50"
    >
      <span class="icon"><i class="far fa-paper-plane mr-2"></i></span>
      Re-submit to Insurer
    </button>
  </div>

  <EditClaimsModal
    v-if="showManageModalResend"
    :showManageModal="true"
    @setModalOff="showManageModalResend = false"
    @saveChanges="handleResendToInsurer()"
    titleProps="Resend Claim to Claimsure?"
    titlePropsSub="Are you sure want to send this claim to Claimsure?"
    titlePropsFooter='Plese choose the button "Cancel" to return to the claim.'
    tittleSaveReplace="Resend to Claimsure"
  >
  </EditClaimsModal>
  <EditClaimsModal
    v-if="showManageModalResendUndone"
    :showManageModal="true"
    @setModalOff="showManageModalResendUndone = false"
    @saveChanges="handleResendToInsurer()"
    :titleProps="
      'Insurer Payment still shows “undone” issues. Resend Claim ' +
      claim.hospital_appointment_id +
      ' to Claimsure anyway?'
    "
    titlePropsSub="Are you sure you want to send this claim to Claimsure without checking the Insurer Payment tab again?"
    titlePropsFooter="Please choose the button “Cancel” to return to the claim."
    tittleSaveReplace="Resend to Claimsure"
  >
  </EditClaimsModal>
  <EditClaimsModal
    v-if="showManageModalResendToSignature"
    :showManageModal="true"
    @setModalOff="showManageModalResendToSignature = false"
    @saveChanges="handleSendClaimToConsultantForApproval()"
    :titleProps="
      'Insurer Payment still shows “undone” issues. Request Consultant Signature for Claim ' +
      claim.hospital_appointment_id +
      ' anyway?'
    "
    titlePropsSub="Are you sure you want to request the Consultant signature again?"
    titlePropsFooter="Please choose the button “Cancel” to return to the claim."
    tittleSaveReplace="Request Consultant Signature"
  >
  </EditClaimsModal>
  <EditClaimsModal
    v-if="showManageModalResendToSignatureDone"
    :showManageModal="true"
    @setModalOff="showManageModalResendToSignatureDone = false"
    @saveChanges="handleSendClaimToConsultantForApproval()"
    :titleProps="'Request Consultant Signature for Claim  ' + claim.hospital_appointment_id + ' ?'"
    titlePropsSub="Are you sure you want to request the Consultant signature again?"
    titlePropsFooter="Please choose the button “Cancel” to return to the claim."
    tittleSaveReplace="Request Consultant Signature"
    tittleCancelReplace="Cancel"
    :optionReverse="true"
  >
  </EditClaimsModal>
  <SpinnerLoader v-if="isLoading" />
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import { userStore } from '@/store/user';
import { ClaimPhase, CompositeClaimStateEnum, CompositeClaimTargetStateEnum } from 'enums';
import EditClaimsModal from '@/components/claim-forms/components/EditClaimsModal.vue';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import {hospitalConfigStore} from '@/store/config';
import claimSection from "@/components/claim-forms/components/ClaimSection.vue";

export default {
  props: {
    // claim: output from /search/claim/composite or /search/claim/consultant
    claim: { required: true },
    filteredDetails: null,
    appointment: null,
    allClaimFormsNum: Number
  },
  components: {
    EditClaimsModal,
    SpinnerLoader,
    ToastComponent
  },
  emits: ['init-claim', 'refresh-files'],
  data() {
    return {
      isConsultant: false,
      insurerPaymentFiles: [],
      processedOn: null,
      showManageModalResend: false,
      showManageModalResendUndone: false,
      showManageModalResendToSignature: false,
      showManageModalResendToSignatureDone: false,
      isLoading: false
    };
  },
  computed: {
    isResendToClaimInsurerVisible() {
      // Not implemented yet because there is no third-party submission system.
      return false;
      /*const claimState = this.claims.claim_state;
      return (
        claimState === CompositeClaimStateEnum.PART_PAID ||
        claimState === CompositeClaimStateEnum.REJECTED ||
        claimState === CompositeClaimStateEnum.PENDED_WITH_HOSPITAL
      );*/
    },
    isDownloadClaimPackVisible() {
      // The claim-pack can only be downloaded when there are files present in the file-store.  Those files
      // are only present once the claim has been finalised.
      return (
        this.claim.claim_phase === ClaimPhase.POST_SUBMIT ||
        this.claim.claim_phase === ClaimPhase.FINISHED ||
        this.claim.claim_state === CompositeClaimStateEnum.READY_FOR_SEND_TO_INSURER
      );
    },
    isSendToClaimInsurerVisible() {
      // Claims become readonly after thay have been "finalised", which converts the draft invoice into
      // a real invoice and stores the claim PDF & invoice into the filestore.  Once they are finalised they can be
      // submitted to the insurer.  Only claims-admins can submit to the insurer.
      const claimState = this.claim.claim_state;
      return claimState === CompositeClaimStateEnum.READY_FOR_SEND_TO_INSURER && !this.isConsultant;
    },
    isSendForConsultantSignatureVisible() {
      const claimState = this.claim.claim_state;
      // Consultants only need to sign claims if there are claim-forms present
      // and there's no point showing the button if a consultant is logged in.
      if (!this.allClaimFormsNum || this.isConsultant) {
        return false;
      }
      return (
        claimState === CompositeClaimStateEnum.DRAFT ||
        claimState === CompositeClaimStateEnum.REJECTED_FOR_AMENDMENT
      );
    },
    isGetSignedVisible() {
      const claimState = this.claim.claim_state;
      // Consultants only need to sign claims if there are claim-forms present.
      if (!this.allClaimFormsNum) {
        return false;
      }
      return (
        claimState === CompositeClaimStateEnum.READY_FOR_APPROVAL ||
        claimState === CompositeClaimStateEnum.REJECTED_FOR_AMENDMENT
      );
    },
    isReadytoSignByConsultantVisible() {
      const claimState = this.claim.claim_state;
      return (
        claimState === CompositeClaimStateEnum.READY_FOR_APPROVAL ||
        claimState === CompositeClaimStateEnum.REJECTED_FOR_AMENDMENT
      );
    },
    isRejectClaimVisible() {
      // Any pre-submitted claim can be rejected at any time.
      const claimState = this.claim.claim_state;
      return (
          claimState === CompositeClaimStateEnum.APPROVED ||
              claimState === CompositeClaimStateEnum.READY_FOR_APPROVAL ||
              claimState === CompositeClaimStateEnum.READY_FOR_SEND_TO_INSURER
      )
    },
    isCompleteClaimVisible() {
      /*
      // Only claims-admins can complete claims.
      if (this.isConsultant) {
        return false;
      }
      // Claims admins can complete claims at any claim-state
      if (!this.allClaimFormsNum) {
        return true;
      }
*/
      // A claim that has been approved by the consultant but has not yet been marked as finalised.
      const claimState = this.claim.claim_state;
      return claimState === CompositeClaimStateEnum.APPROVED;
    }
  },
  methods: {
    async listInsurerPaymentFiles(item) {
      this.insurerPaymentFiles = [];
    },
    handleResendToInsurer() {
      this.listInsurerPaymentFiles();
    },

    handeResendToSignature() {
      // this.showManageModalResendToSignature = true;
      this.listInsurerPaymentFiles(true);
    },

    checkNullProcessedOn(item) {
      this.processedOn = null;
      if (!item) {
        for (const payment of this.insurerPaymentFiles) {
          if (payment.processed_on === null) {
            this.processedOn = true;
            this.showManageModalResendUndone = true;

            return;
          }
        }
        this.processedOn = false;
        this.showManageModalResend = true;
      }
      if (item) {
        for (const payment of this.insurerPaymentFiles) {
          if (payment.processed_on === null) {
            this.processedOn = true;
            this.showManageModalResendToSignature = true;

            return;
          }
        }

        this.processedOn = false;
        this.showManageModalResendToSignatureDone = true;
      }
    },

    async handleSendToClaimInsurer() {
      const oapi = new OpenAPIJSON();
      let req = {
        claim_ref: {
          type: 'id',
          id: this.claim.claim_assoc.id
        },
        reason: 'Request through UI',
        target_state: CompositeClaimTargetStateEnum.ENQUEUE_FOR_SUBMISSION
      };
      await oapi
        .composite_state_change(req)
        .then(res => {
          if (res) {
            this.$refs.toast.showToast(
              'Claim ' + this.claim.hospital_appointment_id + ' marked as enqueued for submission to insurer',
              'fas fa-check',
              'success'
            );

            this.$emit('init-claim');
          } else {
            this.$refs.toast.showToast(res.data.errors[0].message, 'fa-solid fa-triangle-exclamation', 'error');
          }
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },
    async handleSendClaimToConsultantForApproval() {
      this.isLoading = true;
      this.showManageModalResendToSignatureDone = false;
      this.showManageModalResendToSignature = false;
      const oapi = new OpenAPIJSON();
      let req = {
        claim_ref: {
          type: 'id',
          id: this.claim.claim_assoc.id
        },
        reason: 'Request through UI',
        target_state: CompositeClaimTargetStateEnum.READY_FOR_REVIEW
      };
      await oapi
        .composite_state_change(req)
        .then(res => {
          if (res) {
            this.$refs.toast.showToast(
              'Claim ' + this.claim.hospital_appointment_id + ' assigned to consultant for approval',
              'fas fa-check',
              'success'
            );
            setTimeout(() => {
              this.$emit('close-modal');
              this.$emit('appointmentStateChange', this.appointment);
              this.$emit('init-claim');
            }, 1000);
            this.isLoading = false;
          } else {
            this.$refs.toast.showToast(res.data.errors[0].message, 'fa-solid fa-triangle-exclamation', 'error');
            this.isLoading = false;
          }
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
      this.isLoading = false;
    },

    async handleSignClaim() {
      const oapi = new OpenAPIJSON();
      let req = {
        claim_ref: {
          type: 'id',
          id: this.claim.claim_assoc.id
        },
        reason: 'Request through UI',
        target_state: CompositeClaimTargetStateEnum.APPROVED
      };
      await oapi
        .composite_state_change(req)
        .then(res => {
          if (res) {
            this.$refs.toast.showToast(
              'Claim ' + this.claim.hospital_appointment_id + ' signed.',
              'fas fa-check',
              'success'
            );

            setTimeout(() => {
              this.$emit('init-claim');
            }, 1000);
          } else {
            this.$refs.toast.showToast(
              'Cannot sign claim ' + this.claim.hospital_appointment_id + ': ' + res.data.errors[0].message,
              'fa-solid fa-triangle-exclamation',
              'error'
            );
          }
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          this.$refs.toast.showToast(
            'Claim ' + this.claim.hospital_appointment_id + ' can not be signed',
            'fa-solid fa-triangle-exclamation',
            'error'
          );
        });
    },

    async handleRejectClaim() {
      const oapi = new OpenAPIJSON();
      let req = {
        claim_ref: {
          type: 'id',
          id: this.claim.claim_assoc.id
        },
        reason: 'Request through UI',
        target_state: CompositeClaimTargetStateEnum.NEEDS_MORE_WORK
      };
      await oapi
        .composite_state_change(req)
        .then(res => {
          if (res) {
            this.$refs.toast.showToast(
              'Claim ' + this.claim.hospital_appointment_id + ' marked as rejected',
              'fas fa-check',
              'success'
            );

            setTimeout(() => {
              this.$emit('init-claim');
            }, 1000);
          } else {
            this.$refs.toast.showToast(res.data.errors[0].message, 'fa-solid fa-triangle-exclamation', 'error');
          }
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },

    async handleCompleteClaim() {
      const oapi = new OpenAPIJSON();
      let req = {
        claim_ref: {
          type: 'id',
          id: this.claim.claim_assoc.id
        },
        reason: 'Request through UI',
        target_state: CompositeClaimTargetStateEnum.ENQUEUE_FOR_SUBMISSION
      };
      await oapi
        .composite_state_change(req)
        .then(res => {
          if (res) {
            this.$refs.toast.showToast(
              'Claim ' + this.claim.hospital_appointment_id + ' marked as now ready for download',
              'fas fa-check',
              'success'
            );

            setTimeout(() => {
              this.$emit('init-claim');
              this.$emit('refresh-files');
            }, 1000);
          } else {
            this.$refs.toast.showToast(res.data.errors[0].message, 'fa-solid fa-triangle-exclamation', 'error');
          }
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },
    async handleDownloadZip() {
      try {
        const oapi = new OpenAPIJSON();
        let zip_resp = null;
        if (hospitalConfigStore.getters.is_single_consultant_hospital) {
          zip_resp = await oapi.file_zip_full_claim(this.claim.claim_id);
        } else {
          zip_resp = await oapi.file_zip_composite_claim(this.claim.claim_assoc.id);
        }
        this.$refs.toast.showToast('Zip file "' + zip_resp.zip_name + '" downloaded.', 'fas fa-check', 'success');

        let link = document.createElement('a');
        link.innerHTML = '';
        link.download = zip_resp.zip_name;
        link.href = 'data:application/octet-stream;base64,' + zip_resp.b64data;
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        if (
          error.response?.data?.reason ===
          'FILE-013 Nothing to download; there are currently no files associated to the claim.  Marking the claim as complete will allow you to download the claim pack'
        ) {
          this.$refs.toast.showToast(
            'The Claim needs to be completed before downloading claim pack',
            'fa-solid fa-triangle-exclamation',
            'error'
          );
        } else {
          this.$refs.toast.showToast(
            'An error occurred. Please contact the support.',
            'fa-solid fa-triangle-exclamation',
            'error'
          );
        }
      }
    }
  },

  async mounted() {
    if (userStore.getters.user?.is_medic) {
      this.isConsultant = userStore.getters.user.is_medic;
    }
    console.log("ButtonsCompositeClaim this.claims=", this.claim);
  }
};
</script>

<style>
.btn-claim {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 50px;
  padding: 10px 15px;
  height: 40px;
  border: 2px solid #00a7e1;
  border-radius: 50px;
  width: 160px;
}

.btn-claim1 {
  display: flex;
  align-items: center;
  background-color: #00a7e1;
  border-radius: 50px;
  padding: 10px 15px;
  height: 40px;
  width: 160px;
}

.grow {
  flex-grow: 1;
}
</style>
