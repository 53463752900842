<script setup>
import { nextTick, onMounted, ref, computed, watch } from 'vue';

import ClaimFormMenu from '../../components/admission/ClaimFormMenu.vue';
import ClaimWarningSection from '../../components/admission/ClaimWarningSection.vue';
import GardaForm from './claims/GardaForm.vue';
import EsbForm from './claims/EsbForm.vue';
import PomasForm from './claims/PomasForm.vue';
import IrishLifeForm from './claims/IrishLifeForm.vue';
import IrishLifeForm2022 from './claims/IrishLife2022.vue';
import LayaForm from './claims/LayaForm.vue';
import VhiCtForm from './claims/VhiCtForm.vue';
import VhiHospitalForm from './claims/VhiHospitalForm.vue';
import VhiMriForm from './claims/VhiMriForm.vue';
import VhiOncologyForm from './claims/VhiOncologyForm.vue';
import VhiSurgicalForm from './claims/VhiSurgicalForm.vue';
import { useForm } from 'vee-validate';
import { string, object } from 'yup';
import { BaseDatePicker, LegalCheckbox, BaseInput } from 'ui';
import DefaultModal from '@/components/claim-forms/components/DefaultModal.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

import SaveButtonsEditForm from '../../components/claim-forms/components/SaveButtonsEditForm.vue';

import { getAdmissionClaimSectionIds, modifyLayaFormSections } from '../../util/claims/generateClaimIds';
import SignatureDropdown from './SignatureDropdown.vue';

const emit = defineEmits(['edit-mode', 'set-signature', 'update-patient-claimform', 'generate-pdf', 'refresh-data']);

const props = defineProps({
  claimform: Object,
  claimSectionWarnings: Array,
  signature: String,
  disableEditMode: {
    type: Boolean,
    default: true
  },
  hospital_name: String,
  admission: String
});

const claim = ref(props.claimform?.claim_form_type);
const newSignature = ref('');
const editMode = ref(false);
const claimSections = ref([]);
const formRef = ref([]);
const checkFormChanges = ref([]);
const removeNewClaimFormModal = ref(false);
const refToast = ref(null);

const openEditMode = () => {
  editMode.value = true;
  emit('edit-mode', true);
};

const componentKeyVhiHospital = ref(0);
const componentKeyVhiCt = ref(0);
const componentKeyVhiMri = ref(0);
const componentKeyVhiOncology = ref(0);
const componentKeyVhiSurgical = ref(0);
const componentKeyPomas = ref(0);
const componentKeyGarda = ref(0);
const componentKeyEsb = ref(0);
const componentKeyIrishLife = ref(0);
const componentKeyIrishLife2022 = ref(0);
const componentKeyLaya = ref(0);

const resetComponent = () => {
  componentKeyVhiCt.value += 1;
  componentKeyVhiHospital.value += 1;
  componentKeyVhiMri.value += 1;
  componentKeyVhiOncology.value += 1;
  componentKeyVhiSurgical.value += 1;
  componentKeyPomas.value += 1;
  componentKeyGarda.value += 1;
  componentKeyEsb.value += 1;
  componentKeyIrishLife.value += 1;
  componentKeyIrishLife2022.value += 1;
  componentKeyLaya.value += 1;
};
const { values } = useForm({
  validationSchema: object({
    policyNo: string(),
    memberNo2: string()
  })
});
const closeEditMode = () => {
  if (JSON.stringify(formRef.value?.values) !== JSON.stringify(checkFormChanges.value)) {
    removeNewClaimFormModal.value = true;
    return;
  }

  editMode.value = false;

  formRef.value?.setValues(props.claimform);
  emit('edit-mode', false);
};
const saveChanges = () => {
  saveSignature();
  var formValues;

  claim.value === 'prison_officers'
    ? (formValues = {
        ...formRef.value?.values,
        policyNo: values.policyNo,
        memberNo2: values.memberNo2
      })
    : (formValues = formRef.value?.values);

  emit('update-patient-claimform', formValues);
  editMode.value = false;
  emit('edit-mode', false);
  emit('refresh-data');
};
const saveSignature = () => {
  if (newSignature.value) {
    const signatureValue = newSignature.value.split(',')[1];
    if (signatureValue !== props.signature) {
      emit('set-signature', signatureValue);
    }
  }
};
const captureSignature = dataUrl => {
  newSignature.value = dataUrl;
};
const clearSignature = () => {
  newSignature.value = '';
};

const signatureHasWarnings = () => {
  if (!props.claimSectionWarnings) return false;
  return props.claimSectionWarnings.some(warning => warning.id === 'signature-pad-element');
};

const generatePdf = () => {
  emit('generate-pdf');
};

const scrollToField = elementId => {
  if (!editMode.value) editMode.value = true;

  nextTick(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.focus({ preventScroll: true });
    }
  });
};

const claimFormName = computed(() => {
  switch (claim.value.toLowerCase()) {
    case 'garda':
      return 'Garda';
    case 'e_s_b':
      return 'ESB';
    case 'prison_officers':
      return 'Prison Officers';
    case 'irish_life_hospital':
      return 'Irish Life Hospital';
    case 'irish_life_hospital2022':
      return 'Irish Life Hospital 2022';
    case 'laya_inpatient':
      return 'Laya Inpatient';
    case 'vhi_c_t':
      return 'VHI CT';
    case 'vhi_hospital':
      return 'VHI Hospital';
    case 'vhi_mri':
      return 'VHI MRI';
    case 'vhi_oncology':
      return 'VHI Oncology';
    case 'vhi_surgical':
      return 'VHI Surgical';
    default:
      return '';
  }
});

const signatureSectionName = computed(() => {
  switch (claim.value.toLowerCase()) {
    case 'garda':
      return 'Signature';
    case 'e_s_b':
      return 'Signature';
    case 'prison_officers':
      return 'Signature';
    case 'irish_life_hospital':
      return 'Signature';
    case 'irish_life_hospital2022':
      return 'Signature';
    case 'laya_inpatient':
      return 'Signature';
    case 'vhi_c_t':
      return 'Patient or Parent/Legal Guardian (if patient is under 18 years of age) Authorisation';
    case 'vhi_hospital':
      return 'Patient or Parent/Legal Guardian (if patient is under 18 years of age) Authorisation';
    case 'vhi_mri':
      return 'Patient or Parent/Legal Guardian (if patient is under 18 years of age) Authorisation';
    case 'vhi_oncology':
      return 'Patient or Parent/Legal Guardian (if patient is under 18 years of age) Authorisation';
    case 'vhi_surgical':
      return 'Patient or Parent/Legal Guardian (if patient is under 18 years of age) Authorisation';
    default:
      return '';
  }
});

const closeRemoveNewClaimFormModal = () => {
  removeNewClaimFormModal.value = false;

  formRef.value?.setValues(props.claimform);
  editMode.value = false;
  resetComponent();
  emit('edit-mode', false);
  refToast.value.showToast('Changes were reverted .', 'fas fa-check', 'other');
};

if (claim.value === 'laya_inpatient') {
  watch(
    () => formRef.value?.treatmentResultOfAccident?.value,
    () => {
      claimSections.value = modifyLayaFormSections(
        formRef.value?.treatmentResultOfAccident.value,
        formRef.value?.similarIllness.value
      );
    }
  );

  watch(
    () => formRef.value?.similarIllness?.value,
    () => {
      claimSections.value = modifyLayaFormSections(
        formRef.value?.treatmentResultOfAccident.value,
        formRef.value?.similarIllness.value
      );
    }
  );
}

onMounted(async () => {
  if (claim.value === 'laya_inpatient')
    return (claimSections.value = modifyLayaFormSections(
      formRef.value?.treatmentResultOfAccident.value,
      formRef.value?.similarIllness.value
    ));
  claimSections.value = getAdmissionClaimSectionIds(claim.value);
  checkFormChanges.value = JSON.parse(JSON.stringify(formRef.value?.values));
});

defineExpose({ scrollToField });
</script>

<template>
  <div class="flex justify-between items-center mb-3">
    <div class="flex items-center">
      <div class="flex items-center" v-if="disableEditMode">
        <h2 class="s-view-darker-title">Claim Form - {{ claimFormName }}</h2>
        <i
          v-if="!editMode"
          class="fas fa-pen cursor-pointer ml-2 text-xl"
          data-test-id="edit-pen-icon"
          @click="openEditMode"
        ></i>
      </div>
      <span v-else-if="claimSectionWarnings.length > 0">
        <i class="fal fa-circle-exclamation mx-1 text-medosync-dark-orange fa-lg px-2"></i>
      </span>
    </div>
    <div v-if="disableEditMode" class="flex items-center cursor-pointer" @click="generatePdf">
      <i class="fa-regular fa-file-pdf fa-xl me-1"></i>
      <span class="underline text-sm pt-1">Preview form</span>
    </div>
  </div>

  <DefaultModal
    v-if="removeNewClaimFormModal"
    :showManageModal="true"
    :warningIcon="true"
    :title="`Revert all changes from Claim Form ?`"
    text="Are you sure you want to leave this page and revert all changes you made in this claim form?"
    subtext="Please choose the button “Cancel” to review the claim form again."
    secondaryButtonText="Revert changes"
    primaryButtonText="Cancel"
    @secondary-changes="closeRemoveNewClaimFormModal"
    @primary-changes="removeNewClaimFormModal = false"
    @close-modal="removeNewClaimFormModal = false"
  ></DefaultModal>

  <ClaimWarningSection
    v-if="!editMode"
    class="mb-5"
    :show="claimSectionWarnings.length > 0"
    :warnings="claimSectionWarnings"
    :edit-mode="editMode"
    @open-edit-mode="openEditMode"
  />
  <div class="p-4 bg-gray shrink grow relative min-h-[800px]">
    <div class="bg-gray flex absolute form-container-size">
      <ClaimFormMenu v-if="claimSections.length > 0" :sections="claimSections" :warnings="claimSectionWarnings" />
      <div
        class="bg-white popup-scrollbar px-8 z-index-1000"
        id="admission-form-container"
        :class="editMode ? 'pb-12' : ''"
      >
        <GardaForm
          ref="formRef"
          v-if="claim === 'garda'"
          :edit-mode="editMode"
          :warnings="claimSectionWarnings"
          :initial-data="claimform"
          :key="componentKeyGarda"
        />
        <EsbForm
          ref="formRef"
          v-else-if="claim === 'e_s_b'"
          :edit-mode="editMode"
          :warnings="claimSectionWarnings"
          :initial-data="claimform"
          :key="componentKeyEsb"
        />
        <PomasForm
          ref="formRef"
          v-else-if="claim === 'prison_officers'"
          :edit-mode="editMode"
          :warnings="claimSectionWarnings"
          :initial-data="claimform"
          :key="componentKeyPomas"
        />
        <IrishLifeForm
          ref="formRef"
          v-else-if="claim === 'irish_life_hospital'"
          :edit-mode="editMode"
          :warnings="claimSectionWarnings"
          :initial-data="claimform"
          :key="componentKeyIrishLife"
        />
        <IrishLifeForm2022
          ref="formRef"
          v-else-if="claim === 'irish_life_hospital2022'"
          :edit-mode="editMode"
          :warnings="claimSectionWarnings"
          :initial-data="claimform"
          :key="componentKeyIrishLife2022"
        />
        <LayaForm
          ref="formRef"
          v-else-if="claim === 'laya_inpatient'"
          :edit-mode="editMode"
          :warnings="claimSectionWarnings"
          :initial-data="claimform"
          :admission="admission"
          :key="componentKeyLaya"
        />
        <VhiCtForm
          ref="formRef"
          v-else-if="claim === 'vhi_c_t'"
          :edit-mode="editMode"
          :warnings="claimSectionWarnings"
          :initial-data="claimform"
          :key="componentKeyVhiCt"
        />
        <VhiHospitalForm
          ref="formRef"
          v-else-if="claim === 'vhi_hospital'"
          :edit-mode="editMode"
          :warnings="claimSectionWarnings"
          :initial-data="claimform"
          :key="componentKeyVhiHospital"
        />
        <VhiMriForm
          ref="formRef"
          v-else-if="claim === 'vhi_mri'"
          :edit-mode="editMode"
          :warnings="claimSectionWarnings"
          :initial-data="claimform"
          :key="componentKeyVhiMri"
        />
        <VhiOncologyForm
          ref="formRef"
          v-else-if="claim === 'vhi_oncology'"
          :edit-mode="editMode"
          :warnings="claimSectionWarnings"
          :initial-data="claimform"
          :key="componentKeyVhiOncology"
        />
        <VhiSurgicalForm
          ref="formRef"
          v-else-if="claim === 'vhi_surgical'"
          :edit-mode="editMode"
          :warnings="claimSectionWarnings"
          :initial-data="claimform"
          :key="componentKeyVhiSurgical"
        />
        <ClaimSectionTitle id="signature-pad-element-title" :title="signatureSectionName" class="mb-4" />

        <!-- ####Prison_Officers#### -->
        <div v-if="claim === 'prison_officers'">
          <div class="mb-6 mt-6">
            <div class="">
              <div class="flex mb-4">
                <p class="font-bold text-xl mr-2">WARNING:</p>
                <p class="italic text-lg">
                  DO NOT WRITE ON BILLS OR ALTER THEM IN ANY WAY. ENSURE THAT ALL DOCUMENTS IN SUPPORT OF YOUR CLAIM ARE
                  IN ORDER AS YOU HAVE TO ACCEPT RESPONSIBILITY FOR THEM.
                </p>
              </div>
              <div class="flex mb-4">
                <p class="font-bold text-xl mr-2">Declaration:</p>
                <p class="text-lg">
                  I declare that the attached bills relate only to me and/or my registered dependants all of whom are
                  members of the Society and that the information supplied on/with this claim is correct.
                </p>
              </div>
              <div class="flex justify-center">
                <p class="text-lg font-bold">- THE RULES PROVIDE FOR SERIOUS PENALTIES FOR FRAUDULENT CLAIMS -</p>
              </div>
              <div class="w-1/2">
                <BaseInput
                  id="policy-no"
                  name="policyNo"
                  class="mt-6 sm:mt-12"
                  label="Policy No."
                  :edit-mode="editMode"
                  :is-warning="claimSectionWarnings.some(warning => warning.id == 'policy-no')"
                />

                <BaseInput
                  id="member-no-2"
                  name="memberNo2"
                  class="mt-6 sm:mt-12"
                  label="Personnel No. (if retired)"
                  :edit-mode="editMode"
                  :is-warning="claimSectionWarnings.some(warning => warning.id == 'member-no-2')"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- ####Prison_Officers#### -->

        <!-- ####GARDA#### -->
        <div v-if="claim === 'garda'">
          <div>
            <p class="text-sm">
              In electing for private care, I authorise the consultant/hospital concerned to supply all necessary
              information to my insurer including, if requested, copies of my hospital/medical records. I also authorise
              my insurer to pay the appropriate benefits for services provided to the hospital and consultants
              concerned. Charges which are not eligible for benefit will remain my responsibility to settle directly
              with the hospital or consultant.
            </p>
            <br />
            <p>
              I have signed the <u>PRIVATE INSURANCE PATIENT FORM</u> provided to me by the hospital and understood its
              contents.
            </p>

            <p class="text-sm">(Applies to Public Hospitals only)</p>
            <br />
            <p>I declare that the information completed above is true in every respect</p>
            <p class="text-sm">
              NB. If applicable, I acknowledge by signing this form my liability to the Society under the Legal
              Undertaking provided above where a Compensation Claim is initiated by me or a family member on cover at
              that time under my policy.
            </p>
            <br />
          </div>
        </div>
        <!-- ####GARDA#### -->
        <!-- ####VHI#### -->
        <div
          v-if="
            claim === 'vhi_surgical' ||
            claim === 'vhi_hospital' ||
            claim === 'vhi_c_t' ||
            claim === 'vhi_mri' ||
            claim === 'vhi_oncology'
          "
        >
          <div>
            <h3 class="text-sm sm:text-base font-bold mt-2">Data Protection Statement</h3>
          </div>

          <div>
            <p>
              In order to adjudicate on your claim, Vhi will process the personal data that you have provided on this
              form, together with any personal data that you have authorised third parties to provide to us. Certain
              processing of your personal data is required in order for us to adjudicate on your claim and for us to be
              able to operate the business of providing health insurance policies, whereas some processing of your
              personal data is optional. You can indicate your consent to the optional processing of your personal data
              below.
            </p>
            <br />
            <p>
              Vhi Insurance DAC of Vhi House, Lower Abbey Street, Dublin 1 is the company that controls and is
              responsible for processing the personal data in relation to your claim. It will process your personal data
              in accordance with the Vhi Data Protection Statement which has previously been provided to you. If you
              would like another copy of the Vhi Data Protection Statement it is available at Vhi.ie, or you can request
              a copy by calling us on (056) 444 4444.
            </p>

            <h3 class="text-sm sm:text-base font-bold mt-6 sm:mt-6">Obtaining Copies of Your Medical Information</h3>

            <p>
              In order to process and to establish the eligibility and appropriateness of your claim we will contact the
              facility and your treating practitioners (including, where relevant your GP) on your behalf to request a
              copy of all necessary information including, if requested, copies of the facility/medical records relating
              to the treatment and/or services received by you as part of this claim.
            </p>

            <h3 class="text-sm sm:text-base font-bold mt-6 sm:mt-6">Optional Consents</h3>

            <p>
              We would like to process your personal data (or if you are a parent/guardian acting on behalf of a
              dependant under 18 years, the personal data you provide on their behalf) for the purposes set out below.
              This is entirely optional, <span class="font-bold">and will not affect the processing of the claim.</span>
            </p>

            <h3 class="text-sm sm:text-base text-medosync-blue font-bold mt-10">Advisory</h3>

            <LegalCheckbox :edit-mode="editMode" :default-value="false" name="advisoryText" class="mt-4">
              <template #label>
                <p class="text-sm sm:text-base">
                  I consent to Vhi processing personal data in relation to this claim, and past claims, including
                  details of any medical conditions and treatment, in order to undertake analysis and profiling of
                  medical and health insurance needs. I understand Vhi will use this to identify individual needs, which
                  will help Vhi to tailor communications and advice to me in connection with the renewal of my policy
                  either by post, phone, email or SMS (based on my chosen method of communication).
                </p>
              </template>
            </LegalCheckbox>

            <h3 class="text-sm sm:text-base text-medosync-blue font-bold mt-6">Surveys</h3>

            <LegalCheckbox :edit-mode="editMode" :default-value="false" name="surveysText" class="mt-4">
              <template #label>
                <p class="text-sm sm:text-base">
                  I consent to Vhi processing personal data in relation to this claim, and past claims, including
                  details of any medical treatments, to allow Vhi to invite me to participate in surveys. If I am
                  eligible to participate, I consent to Vhi contacting me to ask me to participate by post, phone, email
                  or SMS (based on my chosen method of communication).
                </p>
              </template>
            </LegalCheckbox>

            <h3 class="text-sm sm:text-base text-medosync-blue font-bold mt-6">Direct marketing</h3>

            <LegalCheckbox :edit-mode="editMode" :default-value="false" name="directMarketingText" class="mt-4">
              <template #label>
                <p class="text-sm sm:text-base">
                  I consent to Vhi processing my personal data in relation to this claim, and past claims, including
                  details of any medical conditions and treatments, to offer me personalised products and services which
                  are relevant to my needs by post, phone, email or SMS (based on my chosen method of communication).
                </p>
              </template>
            </LegalCheckbox>

            <h3 class="text-sm sm:text-base font-bold mt-6 sm:mt-12">Withdrawal of Consent</h3>

            <p class="mt-6">
              Please note that where you have given consent to Vhi processing your personal data you may also withdraw
              that consent at any time. If you would like to withdraw your consent, or if you have any other queries, or
              if you wish to change your chosen method of communication, please contact us using any of the following
              channels:
            </p>

            <ul class="mt-6 sm:mt-6 ml-5 list-inside list-disc">
              <li>
                <span class="font-bold">Post:</span> Vhi Healthcare, IDA Business Park, Purcellsinch, Dublin Road,
                Kilkenny
              </li>
              <li><span class="font-bold">E-Mail:</span> info@vhi.ie</li>
              <li><span class="font-bold">Phone:</span> (056) 444 4444</li>
              <li><span class="font-bold">Online:</span> MyVhi or the Health Assistant App</li>
            </ul>
          </div>

          <div>
            <h3 class="text-sm sm:text-base font-bold mt-6 sm:mt-12">Authorisation - YOU MUST SIGN HERE</h3>

            <p class="mt-6 mb-6">
              I declare that the information completed above at the time of signing this declaration is true in every
              respect. I authorise Vhi to pay the appropriate benefits, for services provided, to the treatment facility
              and medical practitioners concerned. I understand that the details of these amounts will be included in my
              Vhi statement of payment and I will contact Vhi directly with any queries. Charges which are not eligible
              for benefit will remain my responsibility to settle directly with the treatment facility/medical
              practitioner concerned.
            </p>
          </div>

          <ul class="mt-6 sm:mt-6 ml-5 list-inside list-disc mb-6">
            <li>
              For completion by the Patient or Parent/Legal Guardian (if patient is under 18 years of age at time of
              admission)
            </li>
            <li class="font-bold">Please sign below with your name</li>
            <li>Please do not use initials</li>
            <li>If for some reasons you cannot sign, you will be able to sign the form at the hospital</li>
          </ul>
        </div>
        <SignatureDropdown
          id="signature-pad-element-container"
          :signature="signature"
          :edit-mode="editMode"
          :claim="claim"
          :signature-has-warnings="signatureHasWarnings"
          @signature-captured="captureSignature"
          @on-clear="clearSignature"
          :insurer="claim"
        />
        <div v-if="claim === 'vhi_surgical'">
          <p class="mt-6 sm:mt-12 mb-4"></p>
          <div class="mb-4">
            <BaseDatePicker
              id="signature-date"
              class="mt-16"
              name="signatureDate"
              label="Date"
              placeholder="dd.mm.yyyy"
            />
            <p class="text-sm italic text-medosync-inactive-grey">This is your appointment date</p>
          </div>

          **For claims in relation to a dependant under 18 years at the time of scan, please note that all
          correspondence and relevant payments will be made to the Policyholder. If the dependant turns 18 while the
          claim is in progress, Vhi will continue to correspond with the Policyholder until the claim is concluded.
          <br /><br />
          Vhi Insurance DAC trading as Vhi Insurance is regulated by the Central Bank of Ireland.
          <br /><br />
          <span class="font-bold"
            >Please check that you have entered your Policy Number in Policy Details section.</span
          >
          <br /><br />
          Please note that the address you provide in Policy Details section is purely for data validation purposes. If
          you need to update your contact details or membership/ personal data, please contact our Customer Services
          Helpline at
          <span class="font-bold">(056) 444 4444.</span>
        </div>

        <div v-if="claim === 'vhi_mri'">
          <p class="mt-6 sm:mt-12 mb-4"></p>
          <div class="mb-4">
            <BaseDatePicker
              id="signature-date"
              class="mt-16"
              name="signatureDate"
              label="Date"
              placeholder="dd.mm.yyyy"
            />
            <p class="text-sm italic text-medosync-inactive-grey">This is your appointment date</p>
          </div>

          **For claims in relation to a dependant under 18 years at the time of scan, please note that all
          correspondence and relevant payments will be made to the Policyholder. If the dependant turns 18 while the
          claim is in progress, Vhi will continue to correspond with the Policyholder until the claim is concluded.
          <br /><br />
          Vhi Insurance DAC trading as Vhi Insurance is regulated by the Central Bank of Ireland.
          <br /><br />
          <span class="font-bold"
            >Please check that you have entered your Policy Number in Policy Details section.</span
          >
          <br /><br />
          Please note that the address you provide in Policy Details section is purely for data validation purposes. If
          you need to update your contact details or membership/ personal data, please contact our Customer Services
          Helpline at
          <span class="font-bold">(056) 444 4444.</span>
        </div>

        <div v-if="claim === 'vhi_c_t'">
          <p class="mt-6 sm:mt-12 mb-4"></p>
          <div class="mb-4">
            <BaseDatePicker
              id="signature-date"
              class="mt-16"
              name="signatureDate"
              label="Date"
              placeholder="dd.mm.yyyy"
            />
            <p class="text-sm italic text-medosync-inactive-grey">This is your appointment date</p>
          </div>

          **For claims in relation to a dependant under 18 years at the time of scan, please note that all
          correspondence and relevant payments will be made to the Policyholder. If the dependant turns 18 while the
          claim is in progress, Vhi will continue to correspond with the Policyholder until the claim is concluded.
          <br /><br />
          Vhi Insurance DAC trading as Vhi Insurance is regulated by the Central Bank of Ireland.
          <br /><br />
          <span class="font-bold">Please check that you have entered your Policy Number in Section 2.</span>
          <br /><br />
          Please note that the address you provide in Policy Details section is purely for data validation purposes. If
          you need to update your contact details or membership/ personal data, please contact our Customer Services
          Helpline at
          <span class="font-bold">(056) 444 4444.</span>
        </div>

        <div class="mt-6 sm:mt-4 mb-4">
          <p>
            MedoSync Ltd. is a data processor of {{ hospital_name }} and submitting the claim on behalf of
            {{ hospital_name }}.
          </p>
          <p>
            MedoSync Ltd.
            <a class="text-medosync-violet-highlight" href="https://www.medosync.com/privacy-policy/" target="_blank"
              >Privacy Policy</a
            >
          </p>
          <p>
            {{ hospital_name }}
            <a
              class="text-medosync-violet-highlight"
              href="https://www.affidea.ie/thootogy/2023/03/Patient_DPN_-October-2019-1.pdf"
              target="_blank"
              >Privacy Policy</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <SaveButtonsEditForm v-if="editMode" @save="saveChanges" @cancel="closeEditMode" />
  <ToastComponent ref="refToast"></ToastComponent>
</template>

<style scoped>
.z-index-2000 {
  z-index: 2000;
}
.form-container-size {
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
}
</style>
