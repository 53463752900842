import * as Sentry from '@sentry/vue';
import router from '../router';

export default {
  install(app, options) {
    Sentry.init({
      app,
      dsn: options.dsn,
      environment: options.environment,
      release: options.release,
      integrations: [
        Sentry.browserTracingIntegration({ router, _experiments: { enableInteractions: true } }),
        Sentry.replayIntegration(),
        // MSEH-275: disable info/warn logging until recursion errors are resolved in the components
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      trackComponents: true, // Enable tracking of component life cycles
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: options.tracePropagationTargets.split(','),
      // Session Replay
      replaysSessionSampleRate: options.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};
