<script setup lang="ts">
import { BaseNumber, BaseInput, BaseDateInput, BasePhoneNumberInput, BaseAddressInput } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="garda-membership-details-title" title="Membership Details" />
    <ul class="text-sm sm:text-base">
      <li>(Member/Guardian must complete and sign this form)</li>
    </ul>
    <BaseNumber
      id="policy-no"
      name="policyNo"
      class="mt-6"
      label="Membership Number"
      helper-text="Staff Number as Policy No"
      :max-length="9"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('policy-no')"
    />
    <BaseInput
      id="patient-name"
      name="patientForename"
      class="mt-6 sm:mt-12"
      label="Patient's Name*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-name')"
    />
    <BaseInput
      id="patient-surname"
      name="patientSurname"
      class="mt-6 sm:mt-12"
      label="Patient's Surname*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-surname')"
    />
    <BaseAddressInput
      id="patient-address"
      name="patientAddress"
      class="mt-6 sm:mt-12"
      label="Address*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-address')"
    />
    <BaseDateInput name="birthDate" class="mt-6 sm:mt-12" required label="Date of Birth*" :edit-mode="editMode" />
    <BasePhoneNumberInput
      id="telephone"
      name="telephone"
      class="mt-6 sm:mt-12"
      label="Telephone No*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('telephone')"
    />
  </div>
</template>
