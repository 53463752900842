<template>
  <div>
    <select
      v-model="selected"
      @change="emitCode"
      class="bg-white border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    >
      <option v-for="item in filteredItems" :key="item.code" :value="item.code">
        {{ item.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    idReference: Number,
    dataList: Array
  },
  data() {
    return {
      selected: ''
    };
  },
  computed: {
    filteredItems() {
      return this.dataList.filter(item => item.id === this.idReference);
    }
  },
  watch: {
    idReference(newVal) {
      this.initializeSelection();
    },
    dataList: {
      deep: true,
      handler() {
        this.initializeSelection();
      }
    }
  },
  methods: {
    emitCode() {
      console.log(this.selected);
      this.$emit('updateCode', this.selected);
    },
    initializeSelection() {
      if (this.filteredItems.length > 0 && !this.selected) {
        this.selected = this.filteredItems[0].code;
        this.emitCode();
      }
    }
  },
  created() {
    this.initializeSelection();
  }
};
</script>
