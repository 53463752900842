<script setup>
import { ref } from 'vue';

const rows = [
  [
    'MRN',
    'Title',
    'First Name',
    'Last Name',
    'DOB',
    'Gender',
    'Addr1',
    'Addr2',
    'Addr3',
    'Addr4',
    'Addr5',
    'Addr6',
    'Landline',
    'Mobile',
    'Email',
    'Appointment ID',
    'Insurer',
    'Policy Name',
    'Policy Number',
    'Admitting Consultant',
    'Admission Date',
    'Admission Time',
    'Discharging Consultant',
    'Discharge Date',
    'Discharge Time',
    'Appointment schedule date',
    'Appointment schedule time',
    'Reason'
  ]
];

let csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');

const downloadTemplateCSV = () => {
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'patient_data_template.csv');
  document.getElementById('downloadTemplate')?.appendChild(link);

  link.click();
};

const collapsed = ref(false);
</script>

<template>
  <div id="downloadTemplate" class="bg-[#4DC0EB] p-4 relative">
    <i
      v-if="!collapsed"
      class="fas fa-chevron-up cursor-pointer absolute top-[20px] right-[20px]"
      @click="collapsed = true"
    />
    <i v-else class="fas fa-chevron-down cursor-pointer absolute top-[20px] right-[20px]" @click="collapsed = false" />

    <h3 v-if="!collapsed" class="text-xl font-medium">Download template</h3>
    <p v-if="!collapsed" class="my-5">
      Please only use the following template to avoid errors while uploading your patient data:
    </p>
    <h5 class="underline cursor-pointer" @click="downloadTemplateCSV">
      Download patient data template (patient_data_template.csv)
    </h5>
  </div>
</template>
