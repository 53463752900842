<template>
  <div
    class="footer-bar"
    :style="{ right: notesOpened ? '400px' : '0', width: notesOpened ? 'calc(100% - 465px)' : 'calc(100% - 65px)' }"
  >
    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
      <button class="cancel-button" @click="cancel" data-test-id="cancel-button">
        <i class="fa-sharp fa-solid fa-xmark"></i>
        Cancel
      </button>

      <div>
        <button
          v-if="activeFinalSaveButton"
          class="final-save-button"
          @click="final"
          data-test-id="save-and-exit-button"
        >
          <i class="fas fa-save"></i>
          Save and Exit
        </button>
        <button class="save-button" @click="save" data-test-id="save-button">
          <i class="fas fa-save" style="margin-right: 10px"></i>
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineEmits, ref, computed } from 'vue';
import { notes_store } from '@/store/notes';

export default {
  name: 'SaveButtonsEditForm',
  emits: defineEmits(['save', 'cancel', 'final']),
  props: {
    activeButton: { required: false },  // Unused, check and remove
    activeFinalSaveButton: { required: false, default: false }
  },
  setup(props, { emit }) {
    const expanded = ref(false);

    const save = () => {
      emit('save');
    };

    const final = () => {
      emit('final');
    };
    const notesOpened = computed(() => {
      return notes_store.state.notesOpened;
    });
    const cancel = () => {
      emit('cancel');
    };
    return {
      save,
      cancel,
      final,
      expanded,
      notesOpened
    };
  }
};
</script>

<style scoped>
.footer-bar {
  position: fixed;
  bottom: 0;
  left: 65px;
  right: 0;
  height: 70px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-top: 1px solid #000000;
  z-index: 3000;
  transition: right 0.8s ease, width 0.8s ease;
}

.save-button {
  background: #00a7e1;
  border-radius: 50px;
  width: 95px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
  margin-top: 34px;
  margin-bottom: 34px;
}

.final-save-button {
  background: #ffffff;
  border-radius: 50px;
  width: 110px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
  margin-top: 34px;
  margin-bottom: 34px;
}

.cancel-button {
  background-color: #cbd5e0;
  color: #000000;
  margin-left: 30px;
  font-family: 'Inter';
  font-style: normal;
}
</style>
