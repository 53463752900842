<template>
  <div class="flex flex-row justify-between items-center my-2">
    <h2 class="font-medium"></h2>
    <div class="cursor-pointer">
      <i class="fa-solid fa-folder-plus"></i>
      <span class="text-sm font-medium underline ml-1" @click="showManageModalUpload = true">Add File</span>
    </div>
  </div>
  <div class="addAttachments">
    <div v-if="files.length === 0" class="noFiles">
      No attachments have been added.
      <div>
        <button @click="showManageModalUpload = true" style="margin-top: 18px; text-decoration: underline">
          Add a file to this claim
        </button>
      </div>
    </div>
    <li
      v-bind:class="{
        files_uploadedDeleted: file.status === true,
        files_uploadedDeletedError: file.status === false
      }"
      class="files_uploaded_"
      v-for="(file, index) in files"
      :key="index"
    >
      <i v-if="file.status === true" :class="getFileIcon(file.file_name)" class="text-white ml-3"></i>
      <i v-else-if="file.status === false" :class="getFileIcon('alert')" class="text-white ml-3"></i>
      <i v-else :class="getFileIcon(file.file_name)" class="ml-3" />

      <h1 v-if="file.status === true" class="ml-11 text-white">File successfully deleted.</h1>
      <h1 v-else-if="file.status === false" class="ml-11 text-white">
        An error occurred. we cannot delete this file. please try again..
      </h1>
      <h1 v-else class="ml-11 cursor-pointer" @click="getFile(file)">
        {{ file.file_name }}
      </h1>

      <i
        v-if="file.status === true"
        class="fa-solid fa-xmark cursor-pointer text-white mr-4 ml-auto"
        @click="removeFileFromList(file.file_name)"
      ></i>
      <i
        v-else-if="file.status === false"
        class="fa-solid fa-xmark cursor-pointer text-white mr-4 ml-auto"
        @click="setFileToDefault(file.file_name)"
      ></i>
      <i
        v-else
        class="fa-regular fa-trash-can cursor-pointer text-black mr-4 ml-auto"
        @click.stop="(showDeleteModal = true), (fileToDelete = file)"
      ></i>
    </li>
  </div>

  <Modal v-if="showManageModalUpload" @close-modal="closeManageModalUpload">
    <template v-slot:header>
      <h3 class="text-lg text-left leading-6 font-medium medo-grey-600">
        Upload a file to appointment [{{ appointmentId }}]
        <UploadComponent
          :appointmentId="appointmentId"
          @close-upload="showManageModalUpload = false"
          @refetch-files="$emit('refetch-files')"
        />
      </h3>
    </template>
  </Modal>

  <Modal v-if="showDeleteModal" @close-modal="closeManageModal">
    <template v-slot:header>
      <h3 class="text-lg text-left leading-6 font-medium medo-grey-600">Delete {{ fileToDelete.file_name }}</h3>
      <br />
      Are you sure you want to delete this file from this claim.
      <br />

      <div class="modalDelete">
        <div class="ml-auto">
          <button class="file-upload-button-canceled py-2 px-4" @click="deleteFile(fileToDelete)">Delete file</button>
          <button class="file-upload-button py-2 px-4" @click="showDeleteModal = false">Cancel</button>
        </div>
      </div>
    </template>
  </Modal>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import { OpenAPIJSON } from 'api';
import { iconMap } from '@/util/helper';
import Modal from '@/components/modal/Modal.vue';
import UploadComponent from '@/components/claim-forms/components/UploadComponent.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'AdmissionAdditionalAttachments',
  props: {
    files: { required: true },
    appointmentId: { required: false }
  },
  components: { Modal, UploadComponent, ToastComponent },
  data() {
    return {
      showDeleteModal: false,
      fileToDelete: '',
      showManageModalUpload: false,
      openApi: new OpenAPIJSON()
    };
  },
  methods: {
    getFileIcon(fileName) {
      if (fileName === 'alert') {
        return 'fa-solid fa-triangle-exclamation';
      }
      const extension = fileName.split('.').pop().toLowerCase();
      return iconMap[extension] || 'fa-regular fa-file';
    },
    closeManageModalUpload() {
      this.showManageModalUpload = false;
    },
    closeManageModal() {
      this.showDeleteModal = false;
    },
    async getFile(file) {
      try {
        const response = await this.openApi.file_get(file.file_name, file.assoc);
        const fileInfo = response.files?.[0];
        if (fileInfo.file_name.endsWith('.pdf')) {
          this.openPDF(fileInfo.data);
        } else {
          this.download(fileInfo.file_name, fileInfo.data);
        }
      } catch (error) {
        console.log('ERROR GETTING FILE: ', error);
        this.$refs.toast.showToast(
          'Error downloading file, please try again.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    openPDF(fileStream) {
      const pdfByteArray = this.base64ToArrayBuffer(fileStream);
      const file = new Blob([pdfByteArray], { type: 'application/pdf' });
      const pdf = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.style = 'display: none';
      a.href = pdf;
      a.target = '_blank';
      a.click();
    },
    base64ToArrayBuffer(base64) {
      const binary_string = window.atob(base64);
      const len = binary_string.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    download(filename, fileStream) {
      const b64 = fileStream;
      const link = document.createElement('a');
      link.innerHTML = '';
      link.download = filename;
      link.href = 'data:application/octet-stream;base64,' + b64;
      document.body.appendChild(link);
      link.click();
    },
    setFileToDefault(fileName) {
      const fileInfo = this.files.find(f => f.file_name === fileName);
      fileInfo.status = null;
    },
    async deleteFile(file) {
      this.showDeleteModal = false;
      const fileInfo = this.files.find(f => f.file_name === file.file_name);
      try {
        await this.openApi.file_delete(file.file_name, file.assoc);
        fileInfo.status = true;
        this.$refs.toast.showToast('File successfully deleted.', 'fas fa-check', 'success');

        setTimeout(() => {
          this.removeFileFromList(file.file_name);
        }, 3000);
      } catch (err) {
        console.log('ERROR DELETING FILE: ', err);
        fileInfo.status = false;
        this.$refs.toast.showToast(
          'Something went wrong deleting file. Please try again.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
        setTimeout(() => {
          fileInfo.status = null;
        }, 3000);
      }
    },
    removeFileFromList(fileName) {
      this.$emit('remove-file-from-list', fileName);
    }
  }
};
</script>
